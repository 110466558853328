(function(angular) {

  "use strict";

  function flipbookPage () {

    function postLink($scope, $element, $attrs, $ctrls) {
      var pageController = $ctrls[0];
      var flipBookController = $ctrls[1];

      flipBookController.registerPage(pageController);

      pageController.setTemplate($scope.template)
      pageController.setRootElement($element)

      $scope.$on('$destroy', function() {
        flipBookController.unregisterPage(pageController);
      })
    }

    return {
      restrict: "E",
      scope: {
        'template': '@'
      },
      replace: true,
      controller: 'PageController',
      controllerAs: 'vm',
      require: ['flipbookPage', '^flipbook'],
      template:'<div class="page"></div>',
      link: postLink
    };
  }

  flipbookPage.$inject = [];

  angular.module('casden.flipbook').directive("flipbookPage", flipbookPage);

})(angular);
