(function(angular) {

  var dependencies = ["casden.utils", "ui.bootstrap"];

  try {
    angular.module("ngRoute");
    dependencies.push("ui.tinymce");
  }
  catch (_error) { }
  finally { }

  angular.module("casden.form", dependencies);

})(angular);
