(function(angular) {

  "use strict";

  function FormElementController ($scope, IFormElement, IComposedFormElement) {
    var vm = this;
    vm.__formElement = null;
    vm.__parentUnregistFunction = null;

    vm.unregister = function() {
      if (vm.__parentUnregistFunction != null) {
        vm.__parentUnregistFunction();
      }
      return vm.__parentUnregistFunction = null;
    };

    vm.register = function(parentController) {
      vm.unregister();
      var parentElement = parentController.getFormElement();
      if (parentElement instanceof IComposedFormElement) {
        vm.__formElement.parent = parentElement;
        return vm.__parentUnregistFunction = parentElement.attachControl(vm.__formElement);
      } else {
        throw "Parent object is not an instance of IComposedFormElement";
      }
    };

    vm.setFormElement = function(element) {
      vm.destroy();
      if (element instanceof IFormElement) {
        return vm.__formElement = element;
      } else {
        throw "Try to set an object which is not an instance of IFormElement";
      }
    };

    vm.getFormElement = function() {
      return vm.__formElement;
    };

    vm.bind = function(iElem, iAttrs) {
      vm.unbind();
      if (vm.__formElement != null) {
        return vm.__formElement.bind(iElem, iAttrs);
      }
    };

    vm.unbind = function() {
      if (vm.__formElement != null) {
        return vm.__formElement.unbind();
      }
    };

    vm.destroy = function() {
      vm.unbind();
      return vm.unregister();
    };
    return vm;
  };

  FormElementController.$inject = ["$scope", "IFormElement", "IComposedFormElement"];

  angular.module('casden.form').controller('FormElementController', FormElementController);

})(angular);
