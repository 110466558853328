(function(angular) {

  "use strict";

  function disabledOnError (FormErrorHandler, $timeout) {

    function disabledOnErrorLink ($scope, element, $attributes, appForm) {
      var formErrorHandler = new FormErrorHandler(appForm, appForm);

      $timeout(function() {
        return $attributes.$observe('disabledOnError', function(value) {
          return formErrorHandler.select(value);
        });
      });

      var watchError = function() {
        return formErrorHandler.hasError();
      };

      var onChange = function(value) {
        if (value) {
          return element.attr("disabled", "disabled");
        } else {
          return element.removeAttr("disabled");
        }
      };

      $scope.$watch(watchError, onChange);
    };

    return {
      restrict: "A",
      scope: false,
      require: "^appForm",
      link: disabledOnErrorLink
    };
  };

  disabledOnError.$inject = ["FormErrorHandler", "$timeout"];

  /*
   * ngDisabled pour les erreurs de formulaire
   */
  angular.module('casden.form').directive("disabledOnError", disabledOnError);

})(angular);
