(function(angular) {

  "use strict";

  var contentDirective = function() {

    var link = function($scope, element, $attributes, boardController) {
      $scope.vm.setBoard(boardController);
      $attributes.$observe('name', function(newName) {
         $scope.vm.setName(newName);
      });
    };

    return {
      restrict: 'E',
      scope: {
        'name': '@'
      },
      templateUrl: 'templates/content.html',
      controller: 'ContentController',
      controllerAs: 'vm',
      require: '^board',
      replace: true,
      link: link
    };
  };

  contentDirective.$inject = [];

  angular.module('app').directive('content', contentDirective);

})(angular);
