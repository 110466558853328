(function(angular) {

  "use strict";

  /*
   *   DESCRIPTION :
   *   ------------------------------------------------------------------------------
   *     Applique le module BootstrapDatepicker à l'élément marqué.
   *
   *   USAGE :
   *   ------------------------------------------------------------------------------
   *   <input  bootstrap-datepicker />
   *
   *   ATTRIBUTS :
   *   ------------------------------------------------------------------------------
   *     Pour une liste complète des attributs voir
   *  https://bootstrap-datepicker.readthedocs.org/
   */
  function bootstrapDatepicker () {

    function bootstrapDatepickerLink (scope, iElem, iAttrs) {
      iElem.datepicker();
      return this;
    }

    return {
      restrict: "A",
      link: bootstrapDatepickerLink
    };

  }

  angular.module('casden.form').directive(
    "bootstrapDatepicker",
    bootstrapDatepicker
  );

})(angular);
