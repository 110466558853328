(function() {

  var AppController = function($scope) {

  };

  AppController.$inject = ['$scope'];

  angular.module('app').controller('AppController', AppController);

})();
