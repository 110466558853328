(function(angular) {

  "use strict";

  function notEmptyHtml () {

    function notEmptyHtmlLink ($scope, element, $attributes, ngModel) {
      if (!(ngModel != null)) {
        return;
      }

      ngModel.$validators.notEmptyHtml = function(modelValue, viewValue) {
        if (viewValue == null) {
          viewValue = modelValue;
        }
        return !(ngModel.$isEmpty(viewValue)) && jQuery("" + viewValue).text().trim() !== "";
      };
    }

    return {
      restrict: "A",
      require: "?ngModel",
      scope: false,
      link: notEmptyHtmlLink
    };
  };

  angular.module('casden.form').directive('notEmptyHtml', notEmptyHtml);

})(angular);
