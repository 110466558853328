(function(angular) {

  "use strict";

  function formErrors () {
    function formErrorsLink (scope, iElem, iAttrs, ctrls) {

      var form = ctrls[0];
      var group = ctrls[1];
      var toAdd = form;

      if ((group != null) && group.getFormElement().isParent(form.getFormElement())) {
        toAdd = group;
      }

      return iElem.find("*").each(function(index, element) {
        var errorName = iAttrs.$normalize(element.tagName.toLowerCase());
        var errorContent = jQuery(element).clone();
        return toAdd.getFormElement().errorMessage(errorName, errorContent);
      });

    }

    return {
      restrict: "E",
      replace: true,
      transclude: true,
      scope: false,
      require: ["^appForm", "^?formGroup"],
      template: "<div ng-show=\"false\" aria-hidden=\"true\" class=\"errors-message\" ng-transclude></div>",
      link: formErrorsLink
    };
  }

  /*
   * Définir les différents messages d'erreur d'un formulaire ou d'un groupe
   */
  angular.module("casden.form").directive("formErrors", formErrors);

})(angular);
