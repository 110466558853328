(function(angular) {

  /**
  * Permet de stocker des tableaux de valeurs dans des cookies.
  */
  function CookieBDDFactory ($window, $q) {

    var localStorage = $window.localStorage;
    var JSON = $window.JSON;

    /**
    * @param string table Nom de la table à charger.
    *
    * @return object Une liste de valeurs dans une table.
    */
    function __openTable(table) {
      var result = JSON.parse(localStorage.getItem('__table_' + table));
      if(result == undefined || result == null) {
        result = {};
      }
      return result;
    }

    /**
    * @param string table Nom de la table à mettre à jour.
    * @param object result Liste de valeurs à mettre à jour.
    */
    function __saveTable(table, result) {
      localStorage.setItem('__table_' + table, JSON.stringify(result));
    }

    /**
    * Supprime une table.
    *
    * @param string table Nom de la table à détruire.
    */
    function __deleteTable(table) {
      localStorage.removeItem('__table_' + table);
      localStorage.removeItem('__table_nkey_' + table);
    }

    /**
    * @param string table Table pour laquelle il faut générer une nouvelle clef.
    *
    * @return int Une clef unique pour la table.
    */
    function __nextKey(table) {
      var nextKey = localStorage.getItem('__table_nkey_' + table);

      if(nextKey == undefined || nextKey == null) {
        nextKey = 0;
      }
      else {
        nextKey = parseInt(nextKey);
      }

      localStorage.setItem('__table_nkey_' + table, (nextKey+1).toString());

      return nextKey;
    }

    /**
    * Récupérer toutes les entrées sauvegardées
    *
    * @param string table Table name.

    * @return promise Qui se résoud avec les données de la table dans un
    *                 tableau.
    */
     var all = function(table) {
       var deferred = $q.defer();

       var tableData = __openTable(table);
       var result = [];
       var object = null;

       for(var key in tableData) {
         object = tableData[key];
         object.$$key = key;
         result.push(object);
       }

       deferred.resolve(result);

       return deferred.promise;
     };

     /**
     * Récupérer une entrée particulière.
     *
     * @param string table Table name.
     * @param string key Clef de l'objet.
     *
     * @return promise Qui se résoud avec l'objet récupéré, ou rejeté si l'objet
     *                 n'existe pas.
     */
     var get = function(table, key) {
       var deferred = $q.defer();

       var tableData = __openTable(table);

       if(tableData[key] == null || tableData[key] == undefined) {
         deferred.reject();
       }
       else {
         deferred.resolve(tableData[key]);
       }

       return deferred.promise;
     };

     /**
     * @param string table Table à ouvrir.
     *
     * @return promise Qui se résoud avec un tableau contenant toutes les clefs
     *                 contenues dans la table.
     */
     var getKeys = function(table) {
       var deferred = $q.defer();

       var tableData = __openTable(table);
       deferred.resolve(Object.keys(tableData));

       return deferred.promise;
     };

     /**
     * Ajouter une entrée dans une table.
     *
     * @param string table Table à modifier.
     * @param object element Element à ajouter.
     *
     * @return promise Qui se résoud quand l'opération est terminée, la nouvelle
     *                 clef est passée en paramètre.
     */
     var insert = function(table, element) {
      var deferred = $q.defer();
      var nextKey = __nextKey(table);
      var tableData = __openTable(table);

      tableData[nextKey] = element;
      __saveTable(table, tableData);

      deferred.resolve(nextKey);

      return deferred.promise;
    };

    /**
    * Modifie une entrée dans la table.
    *
    * @param string table Table à modifier.
    * @param string key Clef de l'objet à modifier.
    * @param object element Nouvel élément.
    *
    * @return promise Qui se résoud quand l'opération est terminée, si la clef
    *                 n'existe pas l'objet promise est rejeté.
    */
    var update = function(table, key,  element) {
     var deferred = $q.defer();
     var tableData = __openTable(table);

     if(tableData[key] == null || tableData[key] == undefined) {
       deferred.reject();
     }
     else {
        tableData[key] = element;
        __saveTable(table, tableData);

        deferred.resolve();
     }

     return deferred.promise;
   };

    /**
    * Supprime un élément d'une table.
    *
    * @param string table Table à modifier.
    * @param string key Clef de l'objet à supprimer.
    */
    var _delete = function(table, key) {
      var deferred = $q.defer();
      var tableData = __openTable(table);

      if(tableData[key] == null || tableData[key] == undefined) {
        deferred.reject();
      }
      else {
        delete tableData[key];

        __saveTable(table, tableData);
        deferred.resolve();
      }

      return deferred.promise;
    };

    /**
    * Service final.
    */
    var service = {
      get: get,
      all: all,
      insert: insert,
      update: update,
      delete: _delete,
      getKeys: getKeys
    };

    return service;
  };

  CookieBDDFactory.$inject = ['$window', '$q'];

  angular.module('app').factory(
    '$cookieBDD',
    CookieBDDFactory
  );

})(angular);
