(function(angular) {

  function CacheManagerController($scope, $appCache, $window, $rootScope) {
    var vm = this;
    var __hide = false;

    $scope.$on('offline', function() {
      __hide = true;
    });

    $scope.$on('online', function() {
      __hide = false;
      $appCache.update();
    });

    vm.isSearchingForUpdates = function() {
      return $appCache.isSearchingForUpdates();
    };

    vm.isInstalling = function() {
      return $appCache.isDownloading() && $appCache.isInstallation();
    };

    vm.isInstalled = function() {
      return $appCache.isInstalled();
    };

    vm.reset = function() {
      $window.location.reload(true);
    };

    vm.isUpdating = function() {
      return $appCache.isDownloading() && $appCache.isUpdate();
    };

    vm.isUpdated = function() {
      return $appCache.isUpdated();
    };

    vm.hasError = function() {
      return $appCache.hasError();
    };

    vm.getInstallationProgress = function() {
      return $appCache.getInstallationProgress();
    };

    vm.isUpToDate = function() {
      return $appCache.isUpToDate();
    };

    vm.isVisible = function() {
      return !__hide && $rootScope.onLine;
    };

    vm.hide = function() {
      __hide = true;
    };

    return vm;
  }

  CacheManagerController.$inject = [
    "$scope",
    "$appCache",
    "$window",
    "$rootScope"
  ];

  angular.module('app').controller(
    'CacheManagerController',
    CacheManagerController
  );

})(angular);
