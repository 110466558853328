(function(angular) {

  "use strict";

  var exists = false;

  try {
    angular.module("ngRoute");
    exists = true;
  }
  catch (_error) {  }
  finally { }

  if (!exists) {
    return;
  }

  /*
   * Editeur léger wysiwyg pour les champs textes, permet le formatage basique :
   * listes, gras, italique, souligné, tab, historique, etc...
   */
  function lighteditor ($timeout, $snakeCase) {

    function lighteditorCompile (element, $attributes) {

      /*
       * Fusion des attributs de la directive avec l'élément textarea
       */
      var textarea = element.find("textarea");
      var toDelete = [];

      for (var attr in $attributes) {
        if (attr.indexOf("$") !== 0 && attr !== "editorValue") {
          if (attr === "class") {
            textarea.addClass($attributes[attr]);
          } else {
            textarea.attr($snakeCase(attr, '-'), $attributes[attr]);
          }
          element.removeAttr($snakeCase(attr, '-'));
        }
      }

      return {
        pre: function($scope, element, $attributes) {

          /*
           * Configuration tinymce
           * @see http://www.tinymce.com/wiki.php/Configuration
           */
          return $scope.mceConfiguration = {
            menubar: false,
            toolbar: "bold italic underline | undo redo | bullist numlist outdent indent",
            theme_url: "./tinymce/themes/modern/theme.js",
            skin_url: "./tinymce/skins/lightgray/",
            browser_spellcheck: true,
            init_instance_callback: function(editor) {
              editor.on('blur change', $scope.notify);
            },
            statusbar: false
          };
        },
        post: function($scope, element, $attributes) {

          /*
           * Branchement avec système de validation
           */
          textarea = element.find('textarea');
          var formControl = textarea.controller('formControl');

          $scope.notify = function(event) {
            return $scope.$apply(function() {
              if (formControl != null) {
                formControl.getFormElement().angularFormController().$setTouched();
                formControl.getFormElement().angularFormController().$setDirty();
              }
            });
          };
        }
      };
    };
    return {
      restrict: "E",
      scope: {
        editorValue: "="
      },
      template: "<textarea class=\"form-control\" ng-model=\"editorValue\" ui-tinymce=\"mceConfiguration\" form-control ></textarea>",
      compile: lighteditorCompile
    };
  };

  lighteditor.$inject = ["$timeout", "$snakeCase"];

  angular.module('casden.form').directive("lighteditor", lighteditor);

})(angular);
