(function(angular) {

  "use strict";

  /**
  *   Controlleur pour la page private.html, il permet de récupérer une liste
  *  de bulletins et de sauvegarder les bulletins sur le serveur distant.
  */
  function PrivateController ($scope, subscriptions, server, $q, $analytics) {

    var vm = this;

    /**
    * Liste des bulletins disponibles.
    */
    vm.subscriptions = [];

    /**
    *  Charge (ou recharge) les informations disponibles en base de donnée.
    *
    *  @return object Promise qui se résoud quand la récupération des données
    *                 est terminée.
    */
    vm.refresh = function() {
      console.log("Récupération des inscriptions...");
      return subscriptions.all().then(function(result) {
    	  vm.subscriptions = result;
        console.log("Inscriptions chargés !", vm.subscriptions);
      });
    };

    /**
    *  Supprime définitivement un bulletin par son index de la base de donnée.
    *
    *  @param Number id Numéro du bulletin à supprimer.
    *
    *  @return object Promise qui se résoud à la suppression du bulletin.
    */
    vm.remove = function(index) {
      var subscription = vm.subscriptions.splice(index, 1);

      console.log("Suppression d'une inscription...", subscription)
      return subscriptions.delete(subscription[0]).then(function(){
    	  console.log("Inscription supprimée !");
      });
    };

    /**
    *  Retourne vrai quand il y a des bulletins disponibles.
    *
    *  @return boolean True si il y a des bulletins disponibles.
    */
    vm.isEmpty = function() {
    	return vm.subscriptions.length <= 0;
    };

    /**
    *   Envoie l'ensemble des bulletins disponibles au serveur, un par un, et
    * supprime les bulletins correctements transmis.
    *
    *   Une fois la liste des bulletins envoyée, cette méthode appel refresh.
    *
    *  @return object Promise qui se résoud une fois le travail terminé.
    */
    vm.sendBDD = function() {
      if(vm.isEmpty()) {
        return vm.refresh();
      }

      $analytics.eventTrack("Tentative d'envoi d'un bulletin de contact");
      var deferred = $q.defer();

      console.log('Envoi de l\'inscription '+ vm.subscriptions[0].nom_naissance + "...");

      server.saveBulletin(vm.subscriptions[0]).then(
        function() {
          $analytics.eventTrack("Bulletin de contact envoyé");
          console.log('Inscription sauvegardée !')
          $analytics.eventTrack("Tentative de suppression d'un bulletin de contact");
          vm.remove(0).then(
            function() {
              $analytics.eventTrack("Bulletin de contact supprimé");
              vm.sendBDD().then(
                function() { deferred.resolve(); },
                function() { deferred.reject(); }
              );
            },
            function() {
              $analytics.eventTrack("Erreur lors de la suppression");
            }
          );
        },
        function() {
          $analytics.eventTrack("Erreur lors de l'envoi d'un bulletin de contact");
          alert([
            "Une erreur est survenue lors de la sauvegarde d'une inscription en BDD.\n",
            "Merci de réessayer ultérieurement."
          ].join(''));

          deferred.reject();
        }
      ); // server.saveBulletin

      return deferred.promise;
    };

    // Refresh automatique à la création.
    vm.refresh();

    return vm;
  };

  PrivateController.$inject = ['$scope', 'subscriptions', 'server', '$q', '$analytics'];

  angular.module('app').controller('PrivateController', PrivateController);

})(angular);