(function(angular) {

  "use strict";

  function IComposedFormElementFactory (IFormElement, $extend) {

    /*
     * Interface générale des éléments composite (ayant des sous élément de formulaire)
     */
    $extend(IComposedFormElement, IFormElement);

    function IComposedFormElement() {
      IComposedFormElement.__super__.constructor.call(this);
      this.controls = [];
    }

    IComposedFormElement.prototype.findByName = function(name) {
      var result = null;

      for (i = 0; i < this.controls.length; ++i) {
        var tmpresult = this.controls[i].findByName(name);
        if (tmpresult != null) {
          result = tmpresult;
        }
      }

      return result;
    };

    IComposedFormElement.prototype.isTouched = function() {
      for (var i = 0; i < this.controls.length; ++i) {
        if (this.controls[i].isTouched()) {
          return true;
        }
      }

      return false;
    };

    IComposedFormElement.prototype.isDirty = function() {
      for (var i = 0; i < this.controls.length; ++i) {
        if (this.controls[i].isDirty()) {
          return true;
        }
      }

      return false;
    };

    IComposedFormElement.prototype.hasError = function(types) {
      if (types == null) {
        types = void 0;
      }

      if (this.disabled) {
        return false;
      }

      var control = null;
      for (var i = 0; i < this.controls.length; i++) {
        control = this.controls[i];
        if (this.canFeedback(control) && control.hasError(types)) {
          return true;
        }
      }
      return false;
    };

    IComposedFormElement.prototype.hasSuccess = function() {
      if (this.disabled) {
        return true;
      }

      var result = true;
      var control = null;

      for (var i = 0; i < this.controls.length; ++i) {
        control = this.controls[i];
        result = result && (!this.canFeedback(control) || control.hasSuccess());
      }

      return result;
    };

    IComposedFormElement.prototype.hasWarning = function(value) {
      if (value == null) {
        value = void 0;
      }

      if (value != null) {
        this.__hasWarning = value;
      }

      if (this.disabled) {
        return false;
      }

      if (this.__hasWarning) {
        return true;
      }

      var control = null;
      for (var i = 0; i < this.controls.length; i++) {
        control = this.controls[i];
        if (this.canFeedback(control) && control.hasWarning()) {
          return true;
        }
      }

      return false;
    };


    /*
     * Ajoute un élément au composite
     *
     * @param IFormElement appFormControl element à ajouter
     * @return function Fonction permettant de détacher l'élément.
     */
    IComposedFormElement.prototype.attachControl = function(appFormControl) {
      this.controls.push(appFormControl);
      return this.__createDetachFunction(appFormControl);
    };


    /*
     * Détache un élément particulier
     *
     * @param IFormElement appFormControl element à supprimer
     */

    IComposedFormElement.prototype.detachControl = function(appFormControl) {
      return this.controls = this.controls.filter(function(element) {
        return element !== appFormControl;
      });
    };


    /*
     * Créée une fonction capable de détâcher un élément particulier.
     *
     * @param IFormElement appFormControl element à détacher
     */

    IComposedFormElement.prototype.__createDetachFunction = function(appFormControl) {
      var self = this;
      var detachFunction = function () {
        return self.detachControl(appFormControl);
      };
      return detachFunction;
    };

    return IComposedFormElement;
  };

  IComposedFormElementFactory.$inject = ["IFormElement", "$extend"];

  angular.module('casden.form').factory("IComposedFormElement", IComposedFormElementFactory);

})(angular);
