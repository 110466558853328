(function(angular) {

  "use strict";

  function formErrormsg (FormErrorHandler, $timeout) {

    function formErrormsgLink (scope, iElem, iAttrs, ctrls) {

      var appForm = ctrls[0];
      var formGroup = ctrls[1];
      var formErrorHandler = new FormErrorHandler(appForm, formGroup || appForm);

      $timeout(function() {
        return iAttrs.$observe('error', function(value) {
          formErrorHandler.select(value);
          return iElem.empty().append(formErrorHandler.errorMessage());
        });
      });

      scope.$watch(
        function() {
          return formErrorHandler.hasError();
        },
        function(value) {
          if (value) {
            return iElem.show();
          } else {
            return iElem.hide();
          }
        }
      );
    };

    return {
      restrict: "E",
      replace: true,
      scope: false,
      require: ["^appForm", "^?formGroup"],
      template: "<span class=\"help-block\"></span>",
      link: formErrormsgLink
    };
  };

  formErrormsg.$inject = ["FormErrorHandler", "$timeout"];

  /*
   * Affichage d'un message d'erreur.
   */
  angular.module('casden.form').directive("formErrormsg", formErrormsg);

})(angular);
