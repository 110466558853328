(function(angular) {

  "use strict";

  function flipbook () {

    function postLink($scope, $elem, $attributes, flipBookController) {
      $scope.$flipbook = $elem.turn({
        when: {
          turned: function(event, page, view) {
            flipBookController.onPageChange(page)
          }
        }
      });
    }

    return {
      restrict: "E",
      scope: null,
      link: postLink,
      controller: 'FlipBookController',
      controllerAs: 'flipbook',
      transclude: true,
      replace: true,
      template: '<div class="flipbook" ng-transclude></div>',
      require: 'flipbook'
    };
  }

  flipbook.$inject = [];

  angular.module('casden.flipbook').directive("flipbook", flipbook);

})(angular);
