(function(angular) {

  "use strict";

  function appForm (FormElement) {

    var appFormPreLink = function(scope, iElem, iAttrs, ctrls) {

      var formController = ctrls[3];
      var parentForm = ctrls[1];
      var parentGroup = ctrls[2];
      var angularFormController = ctrls[0];

      iAttrs.$observe('appForm', function(value, lastValue) {

        if ((value != null) && value.trim() !== "") {
          scope[value] = formController;
        }

        if ((lastValue != null) && lastValue.trim() !== "") {
          return delete scope[lastValue];
        }

      });

      formController.setFormElement(new FormElement(angularFormController));
      formController.bind(iElem, iAttrs);

      if ((parentGroup != null) && (parentForm != null)) {
        if (parentGroup.getFormElement().isParent(parentForm.getFormElement())) {
          formController.register(parentGroup);
        } else {
          formController.register(parentForm);
        }
      } else if ((parentForm != null) || (parentGroup != null)) {
        formController.register(parentForm || parentGroup);
      }

      return scope.$on("$destroy", function() {
        return formController.destroy();
      });
    };
    return {
      restrict: "A",
      require: ["^form", "?^^appForm", "?^formGroup", "appForm"],
      scope: false,
      controller: "FormElementController",
      link: {
        pre: appFormPreLink
      }
    };
  }

  appForm.$inject = ["FormElement"];

  /*
   * Appliquer les animations de validation sur un formulaire.
   */
  angular.module('casden.form').directive("appForm", appForm);

})(angular);
