(function(angular) {

  "use strict";

  function stopSubmitOnError () {

    function stopSubmitOnErrorLink ($scope, element, $attributes, form) {
      element.on("submit", function() {
        $scope.$apply(function() {
          form.getFormElement().angularFormController().$setSubmitted();
        });

        form.getFormElement().hasSuccess();
      });
    }

    return {
      restrict: "A",
      require: "appForm",
      scope: false,
      link: stopSubmitOnErrorLink
    };
  }

  /*
   * Bloque l'envoi du formulaire en cas d'erreur
   */
  angular.module('casden.form').directive("stopSubmitOnError", stopSubmitOnError);

})(angular);
