(function(angular) {

  "use strict";

  /**
  * Permet de surveiller le mode online / offline dans les directives.
  */
  function runOnlineEventRegistering($online, $interval, $rootScope) {

  	if ($online.isOffline()) {
  	  $rootScope.$$wasOffline = true;
  	  $rootScope.$broadcast('offline');
      $rootScope.onLine = false;
  	}
    else {
  	  $rootScope.$$wasOffline = false;
      $rootScope.onLine = true;
    }

    $interval(
      function() {
        var isOnline = $online.isOnline();
        if(isOnline && $rootScope.$$wasOffline) {
      	  $rootScope.$$wasOffline = false;
          $rootScope.onLine = true;
      	  $rootScope.$broadcast('online');
        }
        else if(!isOnline && !$rootScope.$$wasOffline) {
      	  $rootScope.$$wasOffline = true;
          $rootScope.onLine = false;
      	  $rootScope.$broadcast('offline');
        }
      },
      2500
    );

    console.log("Ecouteurs online / offline initialisés...");

  }

  runOnlineEventRegistering.$inject = ["$online", "$interval", "$rootScope"];

  angular.module('app').run(runOnlineEventRegistering);

})(angular);
