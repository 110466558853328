(function (angular) {
  function ContactFormController($scope, subscriptions, $cookies, $window) {
    let vm = this;

    //let syncNames = false;
    let cookies_Conseiller = function () {
      return $cookies.getObject("form-conseiller");
    };

    vm.getTemplate = function () {
      return "./content/body/" + (vm.hasName() ? name : "empty") + ".html";
    };

    // Methodes pour le formulaire principal (preparer-rencontre.html)
    vm.setCivility = function (civilite) {
      vm.prospect.civilite = civilite;
    };

    vm.isMiss = function () {
      return vm.prospect.civilite === "MME";
    };

    vm.isMan = function () {
      return vm.prospect.civilite === "M";
    }; //vm.isMan = function () {

    /* Verif date de naissance */
    vm.isBirthOk = function () {
      var d = new Date(
        vm.prospect.birthYear +
        "-" +
        vm.prospect.birthMonth +
        "-" +
        vm.prospect.birthDay
      );
      return (
        vm.prospect.birthDay > 0 &&
        vm.prospect.birthDay <= 31 &&
        vm.prospect.birthMonth > 0 &&
        vm.prospect.birthMonth <= 12 &&
        vm.prospect.birthYear.toString().length === 4 &&
        d.getTime() < Date.now()
      );
    };

    vm.onDateChanged = function (type) {
      switch (type) {
        case "day":
          vm.prospect.birthDay = vm.prospect.birthDay.replace(/\D/g, "");
          if (vm.prospect.birthDay.length === 2) {
            angular.element("#birth-month").focus();
          }
          break;
        case "month":
          vm.prospect.birthMonth = vm.prospect.birthMonth.replace(/\D/g, "");
          if (vm.prospect.birthMonth.length === 2) {
            angular.element("#birth-year").focus();
          }
          break;
        case "year":
          vm.prospect.birthYear = vm.prospect.birthYear.replace(/\D/g, "");
          break;
      }
      vm.prospect.naissance =
        vm.prospect.birthYear +
        "-" +
        vm.prospect.birthMonth +
        "-" +
        vm.prospect.birthDay;
    }; //vm.onDateChanged = function (type) {

    vm.isInputOk = function (type) {
      regexSansAccent = /[']/;

      switch (type) {
        case "lieu_de_rencontre":
          return (
            vm.prospect.lieu_de_rencontre !== "" &&
            regexSansAccent.test(vm.prospect.lieu_de_rencontre)
          );
          break;
        case "nom_naissance":
          return (
            vm.prospect.nom_naissance !== "" &&
            regexSansAccent.test(vm.prospect.nom_naissance)
          );
          break;
        case "prenom":
          return (
            vm.prospect.prenom !== "" &&
            regexSansAccent.test(vm.prospect.prenom)
          );
          break;
        case "cp":
          return vm.prospect.cp.toString().length === 5;
          break;
        case "ville":
          return (
            vm.prospect.ville !== "" && regexSansAccent.test(vm.prospect.ville)
          );
          break;
        case "phone":
          return (
            vm.prospect.telephone !== "" &&
            vm.prospect.telephone.toString().length === 10 &&
            vm.prospect.telephone.startsWith("0")
          );
          break;
        case "phoneMobile":
          return (
            vm.prospect.telephone !== "" &&
            vm.prospect.telephone.toString().length === 10 &&
            (vm.prospect.telephone.startsWith("06") ||
              vm.prospect.telephone.startsWith("07"))
          );
          break;
        case "email":
          regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]{2,}\.[a-zA-Z0-9._-]{2,4}$/;
          return vm.prospect.email !== "" && regex.test(vm.prospect.email);
          break;
      }
    };

    vm.onInputChanged = function (type) {
      var accent = [
        /[\000-\037]/g,
        // 040 space char enable
        /[\041-\046]/g,
        // 047 apos "'" enable
        /[\050-\054]/g,
        // 055 dash "-"" enable
        /[\056-\100]/g,
        // MAJUSCULE
        /[\133-\140]/g,
        // minuscule
        /[\173-\277]/g,
        /[\300-\306]/g, // A
        /[\340-\346]/g, // a
        /[\310-\313]/g, // E
        /[\350-\353]/g, // e
        /[\314-\317]/g, // i
        /[\354-\357]/g, // i
        /[\322-\330]/g, // O
        /[\362-\370]/g, // o
        /[\331-\334]/g, // U
        /[\371-\374]/g, // u
        /[\321]/g, // N
        /[\361]/g, // n
        /[\307]/g, // C
        /[\347]/g, // c
      ];
      var noaccent = [
        "",
        "",
        "",
        "",
        "",
        "",
        "A",
        "a",
        "E",
        "e",
        "I",
        "i",
        "O",
        "o",
        "U",
        "u",
        "N",
        "n",
        "C",
        "c",
      ];

      switch (type) {
        case "nom_naissance":
          for (var i = 0; i < accent.length; i++) {
            vm.prospect.nom_naissance = vm.prospect.nom_naissance.replace(
              accent[i],
              noaccent[i]
            );
          }
          break;
        case "prenom":
          for (var i = 0; i < accent.length; i++) {
            vm.prospect.prenom = vm.prospect.prenom.replace(
              accent[i],
              noaccent[i]
            );
          }
          break;
        case "cp":
          vm.prospect.cp = vm.prospect.cp.replace(/\D/g, "");
          break;
        case "ville":
          for (var i = 0; i < accent.length; i++) {
            vm.prospect.ville = vm.prospect.ville.replace(
              accent[i],
              noaccent[i]
            );
          }
          break;
        case "phone":
          vm.prospect.telephone = vm.prospect.telephone.replace(/\D/g, "");
          break;
      }
    };

    /*
        vm.isCPOk = function() {
        return vm.prospect.cp.toString().length === 5;
        }
    */

    vm.isOptinOk = function () {
      return (
        vm.prospect.email_publicitee === "N" &&
        vm.prospect.telephone_publicitee === "N" &&
        vm.prospect.sms_publicitee === "N"
      );
    };

    vm.isOptinEmailOk = function () {
      if (vm.prospect.email_publicitee === "O") {
        if (vm.prospect.email !== "" && vm.isInputOk("email")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    vm.isOptinPhoneOk = function () {
      if (vm.prospect.telephone_publicitee === "O") {
        if (vm.prospect.telephone !== "" && vm.isInputOk("phone")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    vm.isOptinSmsOk = function () {
      if (vm.prospect.sms_publicitee === "O") {
        if (vm.prospect.telephone !== "" && vm.isInputOk("phoneMobile")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    let isValid = function () {
      let birthDay = Number(vm.prospect.birthDay);
      let birthMonth = Number(vm.prospect.birthMonth);

      /* Verif date de naissance */
      //vm.isBirthOk = (vm.prospect.birthDay > 0 && vm.prospect.birthDay <= 31) && (vm.prospect.birthMonth > 0 && vm.prospect.birthMonth <= 12) && (vm.prospect.birthYear.toString().length === 4);
      /* Verif code postal */
      //vm.isCPOk = isCPOkkkk();
      /* Verif telephone */
      //vm.isPhoneOk = document.getElementById('phone').checkValidity();
      /* Verif email */
      //vm.isEmailOk = document.getElementById('mail').checkValidity();
      /* Verif champs obligatoires */
      vm.isFieldsOk =
        vm.prospect.civilite &&
        vm.prospect.prenom &&
        vm.prospect.nom_naissance &&
        vm.prospect.birthYear &&
        vm.prospect.birthMonth &&
        vm.prospect.birthDay &&
        vm.prospect.cp &&
        vm.prospect.ville &&
        (vm.prospect.telephone || vm.prospect.email) &&
        vm.prospect.projet &&
        vm.prospect.email_publicitee &&
        vm.prospect.telephone_publicitee &&
        vm.prospect.sms_publicitee;

      //console.log("vm.prospect.type==>"+JSON.stringify(vm.prospect,4));

      return (
        vm.isFieldsOk &&
        vm.isBirthOk() &&
        !vm.isInputOk("nom_naissance") &&
        !vm.isInputOk("prenom") &&
        vm.isInputOk("cp") &&
        !vm.isInputOk("ville") &&
        !vm.isOptinOk() &&
        vm.isOptinEmailOk() &&
        vm.isOptinPhoneOk() &&
        vm.isOptinSmsOk()
      );
    }; // let isValid = function () {

    let transformProspect = function () {
      vm.prospect.naissance =
        vm.prospect.birthYear +
        "-" +
        vm.prospect.birthMonth +
        "-" +
        vm.prospect.birthDay;
      vm.prospect.projet =
        vm.prospect.projet === "pas_projet" ? "" : vm.prospect.projet;
      //vm.prospect.societaire = vm.prospect.isCasden ? 'oui': 'non';
      //vm.prospect.client_bp = vm.prospect.isBP ? 'oui': 'non';
      //vm.prospect.projet_immediat = vm.prospect.projet_immediat ? 'oui' : 'non';

      delete vm.prospect.birthDay;
      delete vm.prospect.birthYear;
      delete vm.prospect.birthMonth;
      //delete vm.prospect.isCasden;
      //delete vm.prospect.isBP;
      //delete vm.prospect.contacts;
    }; // let transformProspect = function () {

    vm.prospect = {
      civilite: "",
      prenom: "",
      nom_naissance: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      naissance: "",
      cp: "",
      ville: "",
      telephone: "",
      email: "",
      projet: "",
      email_publicitee: "",
      telephone_publicitee: "",
      sms_publicitee: "",
    };
    /*
    vm.days=[];
    for (i = 1; i < 31; i++) {
      vm.days[i]=i;
    }
    console.log(JSON.stringify(vm.days));
    */

    // liste pour preparer-rencontre.html
    vm.rencontreTempsOptions = [{
        value: "82",
        label: "Rencontres EN (dont journées de rentrée INSPE, ESR)",
      },
      {
        value: "81",
        label: "Rencontres FP (dont RESP)",
      },
      {
        value: "80",
        label: "Ecole de santé (IFSI, IFAS)",
      },
    ];
    // liste pour preparer-rencontre.html
    vm.codeEtablissementBanqueTempsOptions = [{
        value: "047",
        label: "BP Alsace Lorraine Champagne (047)",
      },
      {
        value: "009",
        label: "BP Aquitaine Centre Atlantique (009)",
      },
      {
        value: "068",
        label: "BP Auvergne Rhônes Alpes (068)",
      },
      {
        value: "008",
        label: "BP Bourgogne Franche-Comté (008)",
      },
      {
        value: "038",
        label: "BP Grand Ouest (038)",
      },
      {
        value: "046",
        label: "BP Méditerranée (046)",
      },
      {
        value: "035",
        label: "BP Nord (035)",
      },
      {
        value: "078",
        label: "BP Occitane (078)",
      },
      {
        value: "002",
        label: "BP Rives de Paris (002)",
      },
      {
        value: "066",
        label: "BP Sud (066)",
      },
      {
        value: "087",
        label: "BP Val de France (087)",
      },
    ];

    vm.goPrivate = function () {
      window.location.href = "/private.html";
    };

    vm.reset = function () {
      vm.prospect = {
        civilite: "",
        prenom: "",
        nom_naissance: "",
        birthDay: "",
        birthMonth: "",
        birthYear: "",
        naissance: "",
        cp: "",
        ville: "",
        telephone: "",
        email: "",
        projet: "",
        email_publicitee: "",
        telephone_publicitee: "",
        sms_publicitee: "",
      };
      angular.extend(vm.prospect, cookies_Conseiller());
    }; // vm.reset = function () {

    vm.captureConseillerCookie = function () {
      let date = new Date();
      date.setTime(date.getTime() + 360 * 24 * 3600 * 1000);

      $cookies.putObject(
        "form-conseiller", {
          rencontre_temps: vm.prospect.rencontre_temps,
          lieu_de_rencontre: vm.prospect.lieu_de_rencontre,
          code_etablissement: vm.prospect.code_etablissement,
          structure_point_vente: vm.prospect.structure_point_vente,
          portefeuille_commercial: vm.prospect.portefeuille_commercial,
          matricule_conseiller: 'CASDEN',
        }, {
          expires: date,
        }
      ); //$cookies.putObject('form-conseiller', {

      if (vm.isInputOk("lieu_de_rencontre")) {
        vm.rencontreValide = false;
      } else {
        if (
          cookies_Conseiller() != undefined &&
          Object.getOwnPropertyNames(cookies_Conseiller()).length >= 6
        ) {
          vm.rencontreValide = true;
        }
      }
    }; // vm.captureConseillerCookie = function () {
    /*
        vm.isInputOk1 = function (type) {
          regexSansAccent = /[']/;

          switch (type) {
            case "lieu_de_rencontre":
              console.log(regexSansAccent.test(vm.prospect.lieu_de_rencontre));
              return (
                vm.prospect.lieu_de_rencontre !== "" &&
                regexSansAccent.test(vm.prospect.lieu_de_rencontre)
              );
              break;
          }
        };
    */
    vm.goBulletin = function () {
      if (cookies_Conseiller() != undefined) {
        if (Object.getOwnPropertyNames(cookies_Conseiller()).length >= 6) {
          vm.bulletinok = false;
          vm.bulletin = true;
          vm.pRencontre = true;
          vm.reset();
        }
      } else {
        vm.pRencontre = false;
      }
    };

    vm.cancel = function () {
      vm.captureConseillerCookie();
      vm.bulletinok = false;
      vm.bulletin = false;
      vm.reset();
    };

    vm.closeModal = function () {
      if (
        cookies_Conseiller() != undefined &&
        Object.getOwnPropertyNames(cookies_Conseiller()).length >= 6
      ) {
        vm.pRencontre = true;
      } else {
        vm.pRencontre = false;
      }
      $scope.$close();
    };

    vm.submitForm = function () {
      if (isValid()) {
        transformProspect();
        vm.captureConseillerCookie();
        vm.prospect.inscription = new Date();

        console.log("vm.prospect.type==>" + JSON.stringify(vm.prospect, 4));

        return subscriptions.post(vm.prospect).then(function () {
          vm.bulletin = false;
          vm.bulletinok = true;
        });
      } else {
        vm.showErrors = true;
      }
    }; //vm.submitForm = function () {

    vm.reset();

    return vm;
  } //function ContactFormController($scope, subscriptions, $cookies, $window) { //ligne 3

  ContactFormController.$inject = [
    "$scope",
    "subscriptions",
    "$cookies",
    "$window",
  ];
  angular
    .module("app")
    .controller("ContactFormController", ContactFormController);
})(angular);
