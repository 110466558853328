(function(angular) {

  "use strict";

  var stopDirective = function() {

    function stopDirectiveLink($scope, $element, $attributes) {
      var wasRunning = false;

      $element.on('play', function() {
        wasRunning = true;
      })

      $element.on('pause', function() {
        var value = $attributes.stop;

        if(value === false || value.trim().toLowerCase() === "false") {
          wasRunning = false;
        }
      })

      $attributes.$observe('stop', function(newValue) {
        if(newValue == null) {
          newValue = "false";
        }

        if(wasRunning) {
          if(newValue === false || newValue.trim().toLowerCase() === "false") {
            $element[0].play();
          }
          else {
            $element[0].pause();
          }
        }
      })
    }

    return {
      restrict: "A",
      link: stopDirectiveLink
    };
  };

  angular.module('app').directive('stop', stopDirective);

})(angular);
