(function(angular) {

  "use strict";

  function FormElementFactory (IComposedFormElement, $extend) {

    var VALIDATION_POLICY_ON_TOUCH = "ontouch";
    var VALIDATION_POLICY_ON_SUBMIT = "onsubmit";
    var VALIDATION_POLICY_ON_START = "onstart";
    var VALIDATION_POLICY_INHERIT = "inherit";
    var VALIDATION_POLICY_NO_VALIDATE = "none";

    $extend(FormElement, IComposedFormElement);

    function FormElement(__angularFormController) {
      this.__angularFormController = __angularFormController;
      FormElement.__super__.constructor.call(this);
      this.validationPolicy = VALIDATION_POLICY_ON_TOUCH;
      this.__attrsBinder.use("validationPolicy");
    }

    FormElement.prototype.angularFormController = function() {
      return this.__angularFormController;
    };

    FormElement.prototype.canFeedback = function(child) {
      if (child == null) {
        child = this;
      }

      var result = false;

      switch (this.validationPolicy.toLowerCase()) {
        case VALIDATION_POLICY_ON_TOUCH:
          result = child.isTouched() || this.__angularFormController.$submitted;
          break;
        case VALIDATION_POLICY_ON_SUBMIT:
          result = this.__angularFormController.$submitted;
          break;
        case VALIDATION_POLICY_ON_START:
          result = true;
          break;
        case VALIDATION_POLICY_INHERIT:
          result = true;
          break;
        default:
          result = false;
      }

      if (this.parent != null) {
        result = result && this.parent.canFeedback(child);
      }

      return result;
    };

    return FormElement;
  };

  FormElementFactory.$inject = ["IComposedFormElement", "$extend"];

  angular.module('casden.form').factory("FormElement", FormElementFactory);

})(angular);
