(function(angular) {

  "use strict";

  function formControl (FormControlElement) {

    function formControlPreLink (scope, iElem, iAttrs, ctrls) {

      var parentController = ctrls[0];
      var controlController = ctrls[1];

      if ((parentController != null) && (controlController != null)) {
        controlController.setFormElement(new FormControlElement);
        controlController.register(parentController);
        controlController.bind(iElem, iAttrs);
        scope.$on("$destroy", function() {
          return controlController.destroy();
        });
      } else {
        controlController.destroy();
      }

    }

    return {
      restrict: "A",
      scope: false,
      require: ["^?formGroup", "?formControl"],
      controller: "FormElementController",
      link: {
        pre: formControlPreLink
      }
    };
  }

  formControl.$inject = ["FormControlElement"];

  /*
   * champ d'un form-group
   */
  angular.module('casden.form').directive("formControl", formControl);

})(angular);
