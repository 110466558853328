(function(angular) {

  "use strict";

  /*
   * Format DATE MySQL, utilisée par l'objet Moment de la bibliothèque Moment.js
   * pour formater les dates correctement.
   */
  var MYSQL_DATE_FORMAT = "YYYY-MM-DD";

  /*
   * Date nulle MySQL, utilisée quand la valeur du champ ou de la variable associée
   * est nulle.
   */
  var MYSQL_NULL_DATE = "0000-00-00";

  /*
   * Directive appliquant un test et un binding de type date sur un champ de formulaire.
   *
   * @see  https://docs.angularjs.org/guide/directive  Page de la documentation angular sur les directives.
   *
   * Cette directive angular permet d'appliquer un test de date sur un champ du formulaire,
   * cet à dire de tester que la valeur du champ correspond bien à un format défini, et transformer
   * cette valeur en date MySQL valide dans la variable du modèle associée.
   *
   * Bien entendu ce binding est à double sens, cet à dire que si la variable du modèle
   * correspondant au champ est modifiée par une nouvelle date MySQL valide, la valeur du
   * champ sera mise à jour dans le format défini.
   *
   * Une valeur de champ vide est considérée comme valide, afin d'éviter ce problème, il faut
   * utiliser l'attribut HTML5 {@link http://www.w3schools.com/tags/att_input_required.asp required}.
   *
   * Pour utiliser cette directive il suffit d'ajouter l'attribut date-type à votre champ de formulaire,
   * et d'y préciser un format de date d'entrée en utilisant les codes de {@link http://momentjs.com/docs/#/parsing/string-format/ moments.js}.
   *
   * Exemple :
   *
   * <input type="text" date-type="DD/MM/YYYY" name="dateEmbauche" />
   *
   * Il est de plus possible de tester si la date est bien avant ou / et après deux autres dates.
   * Afin d'appliquer ce test complémentaire, il faut ajouter les attributs date-type-before, et date-type-after
   * qui prennent en paramètre soit une variable JS soit une valeur contenant une date MySQL valide.
   *
   * Exemple :
   *
   * <input type="text" date-type="DD/MM/YYYY" date-type-before="dates.depart" date-type-after="'2014-01-01'" name="dateEmbauche" />
   *
   */
  function dateType () {

    function dateTypeLink ($scope, element, $attributes, ngModel) {

      /*
       * Retourne le format de date à utiliser pour la valeur en entrée
       * coté vue.
       *
       * Si la valeur en entrée n'est pas définie, cette fonction retournera
       * le format MySQL par défaut.
       *
       * @return string Le format de date d'entrée (coté vue).
       */
      function getFormat () {
        if (!ngModel.$isEmpty($attributes.dateType)) {
          return $attributes.dateType;
        } else {
          return MYSQL_DATE_FORMAT;
        }
      }

      /*
       * Vérifie si une valeur passée en paramètre est considérée
       * comme une valeur vide.
       *
       * Une valeur vide est soit une valeur considérée comme vide
       * par le modèle, ou une valeur nulle au format MySQL.
       *
       * @param string value Valeur à tester.
       *
       * @return boolean True si la valeur est considérée comme une valeur vide, false sinon.
       *
       */
      function isEmpty (value) {
        return ngModel.$isEmpty(value) || value === MYSQL_NULL_DATE;
      }

      /*
       * Retourne l'objet Moment (moment.js) en fonction de la valeur
       * entrée dans la vue (champ du formulaire).
       *
       * La conversion ce fait en fonction du format entré, si le format
       * n'est pas valide, il est possible de le vérifier grâce à la méthode
       * {@link http://momentjs.com/docs/#/parsing/is-valid/ isValid()} de l'objet
       * Moment retourné.
       *
       * @param string value Valeur du champ du formulaire.
       *
       * @return Moment Date Moment associée.
       *
       */
      function getDateFromView (value) {
        return moment(value, getFormat(), true);
      }

      /*
       * Retourne l'objet Moment (moment.js) en fonction de la valeur
       * entrée dans le modèle.
       *
       * La conversion ce fait à partir du format MySQL par défaut, si le format
       * n'est pas valide, il est possible de le vérifier grâce à la méthode
       * {@link http://momentjs.com/docs/#/parsing/is-valid/ isValid()} de l'objet
       * Moment retourné.
       *
       * @param  string  value  Valeur du modèle.
       *
       * @return Moment      Date Moment associée.
       */
      function getDateFromModel (value) {
        return moment(value, MYSQL_DATE_FORMAT, true);
      }

      /*
       * Valide que la date est bien située après une date de référence.
       *
       * Ce test ne s'effectue que si l'attribut date-type-after est
       * défini et valide. Ce test se base sur la méthode {@link http://momentjs.com/docs/#/query/is-after/ isAfter()}
       * de l'objet Moment.
       *
       * @param  string  modelValue  Valeur entrée dans le modèle.
       * @param  string  viewValue  Valeur entrée dans la vue.
       *
       * @return  boolean        True si le résultat du test est valide.
       */
      ngModel.$validators.dateAfter = function(modelValue, viewValue) {
        if (viewValue == null) {
          viewValue = modelValue;
        }

        if (isEmpty(viewValue) || isEmpty($attributes.dateTypeAfter)) {
          return true;
        }

        var date = viewValue != null ? getDateFromView(viewValue) : getDateFromModel(modelValue);

        return date.isAfter(moment($attributes.dateTypeAfter, MYSQL_DATE_FORMAT, true)) || date.format(MYSQL_DATE_FORMAT) === $attributes.dateTypeAfter;
      };

      /*
       * Valide que la date est bien située avant une date de référence.
       *
       * Ce test ne s'effectue que si l'attribut date-type-before est
       * défini et valide. Ce test se base sur la méthode
       * {@link http://momentjs.com/docs/#/query/is-before/ isBefore()}
       * de l'objet Moment.
       *
       * @param string modelValue Valeur entrée dans le modèle.
       * @param string viewValue Valeur entrée dans la vue.
       *
       * @return boolean True si le résultat du test est valide.
       */
      ngModel.$validators.dateBefore = function(modelValue, viewValue) {
        if (viewValue == null) {
          viewValue = modelValue;
        }

        if (isEmpty(viewValue) || isEmpty($attributes.dateTypeBefore)) {
          return true;
        }

        var date = viewValue != null ? getDateFromView(viewValue) : getDateFromModel(modelValue);
        return date.isBefore(moment($attributes.dateTypeBefore, MYSQL_DATE_FORMAT, true)) || date.format(MYSQL_DATE_FORMAT) === $attributes.dateTypeBefore;
      };

      /*
       * Vérifie que la date de la vue est dans un format valide, format
       * défini par l'utilisateur avec l'attribut date-type.
       *
       * Ce test se base sur la méthode {@link http://momentjs.com/docs/#/parsing/is-valid/ isValid()}
       * de l'objet Moment.
       *
       * @param string modelValue Valeur entrée dans le modèle.
       * @param string viewValue Valeur entrée dans la vue.
       *
       * @return boolean True si le résultat du test est valide.
       */
      ngModel.$validators.dateFormat = function(modelValue, viewValue) {
        if (!isEmpty(viewValue)) {
          return getDateFromView(viewValue).isValid();
        } else if (!isEmpty(modelValue)) {
          return getDateFromModel(modelValue).isValid();
        } else {
          return true;
        }
      };

      /*
       * Transforme une date entrée dans le formulaire
       * en date au format MySQL dans le modèle.
       *
       * Si la date est nulle ou invalide, cette fonction
       * retournera la date nulle au format MySQL.
       *
       * @param string value Valeur entrée dans la vue.
       *
       * @return string Une date au format MySQL.
       */
      ngModel.$parsers.unshift(function(value) {
        if (isEmpty(value)) {
          return null;
        }

        var date = getDateFromView(value);

        if (date.isValid()) {
          return date.format(MYSQL_DATE_FORMAT);
        } else {
          return MYSQL_NULL_DATE;
        }
      });

      /*
       * Transforme une date entrée dans le modèle
       * en date au format affiché dans la vue.
       *
       * Si la date est invalide ou nulle, elle est
       * retournée sans aucun traitement supplémentaire.
       *
       * @param string value Valeur entrée dans le modèle.
       *
       * @return string Une date au format souhaitée de sortie défini dans l'attribut date-type.
       */
      ngModel.$formatters.unshift(function(value) {
        if (isEmpty(value)) {
          return '';
        }

        var date = getDateFromModel(value);

        if (date.isValid()) {
          return date.format(getFormat());
        } else {
          return value;
        }
      });

      /*
       * Installe un écouteur sur la valeur définie dans l'attribut
       * date-type-before, si cette valeur vient à changer, le champ est
       * automatiquement revalidé.
       */
      $attributes.$observe('dateTypeBefore', function(newVal, oldVal) {
        if (newVal != null) {
          return ngModel.$validate();
        }
      });

      /*
       * Installe un écouteur sur la valeur définie dans l'attribut
       * date-type-after, si cette valeur vient à changer, le champ est
       * automatiquement revalidé.
       */
      $attributes.$observe('dateTypeAfter', function(newVal, oldVal) {
        if (newVal != null) {
          return ngModel.$validate();
        }
      });
    };

    return {
      restrict: "A",
      require: "ngModel",
      scope: false,
      link: dateTypeLink
    };
  }

  angular.module('casden.form').directive("dateType", dateType);

})(angular);
