(function(angular) {

  "use strict";

  var VALUE_REGEX = /^\d*-\d*-\d*$/;

  function DateMultifieldController ($scope) {
    var vm = this;

    vm.jourField = function() {
      return $scope.dateForm[$scope.name + "_jour"];
    };

    vm.moisField = function() {
      return $scope.dateForm[$scope.name + "_mois"];
    };

    vm.anneeField = function() {
      return $scope.dateForm[$scope.name + "_annee"];
    };

    vm.valueField = function() {
      return $scope.dateForm[$scope.name];
    };

    vm.isDirty = function() {
      return vm.jourField().$dirty ||
             vm.moisField().$dirty ||
             vm.anneeField().$dirty;
    };

    vm.isTouched = function() {
      return vm.jourField().$touched ||
             vm.moisField().$touched ||
             vm.anneeField().$touched;
    };

    vm.checkValue = function() {
      $scope.value =  ($scope.annee || "") + '-' + ($scope.mois || "") + '-'
                      + ($scope.jour || "");

      if($scope.value == "--") $scope.value = null;
    };

    vm.render = function(value) {
      if(VALUE_REGEX.test(value)) {
        var tokens = value.split("-");

        $scope.jour = parseInt(tokens[0]);
        $scope.mois = parseInt(tokens[1]);
        $scope.annee = parseInt(tokens[2]);
        $scope.value = value;
      }
      else {
        $scope.jour = $scope.mois = $scope.annee = null;
        $scope.annee = value;
        $scope.value = value;
      }
    };

    return vm;
  }

  angular.module('casden.form').controller(
    'DateMultifieldController',
    DateMultifieldController
  );

})(angular);
