(function(angular) {

  "use strict";

  function icheck ($timeout, $parse, FormControlElement) {

    function icheckPreLink (scope, iElem, $attrs, ctrls) {

      var parentController = ctrls[0];
      var controlController = ctrls[1];

      controlController.setFormElement(new FormControlElement);
      controlController.register(parentController);
      controlController.bind(iElem, $attrs);

      scope.$on("$destroy", function() {
        controlController.destroy();
      });

    }

    function icheckPostLink ($scope, element, $attrs, ctrls) {
      var ngModel = ctrls[2];
      return $timeout(function() {

        var value = $attrs["value"];

        jQuery(element).iCheck({
          checkboxClass: $attrs.icheck || undefined,
          radioClass: $attrs.icheck || undefined,
          insert: $attrs.insert || undefined,
          aria: true
        });

        $scope.$watch($attrs["ngModel"], function(newValue) {
          jQuery(element).iCheck("updated");
          if (newValue != null) {
            ngModel.$setTouched();
            ngModel.$setDirty();
          }
        });

        element.on("ifChanged", function(event) {
          $scope.$apply(function() {
            if (element.attr("type") === "checkbox") {
              if (!($attrs["value"] != null) || $attrs["value"].trim() === "" || $attrs["value"] === NaN) {
                ngModel.$setViewValue(event.target.checked);
              } else {
                ngModel.$setViewValue($attrs['value']);
              }
            } else if (element.attr('type') === 'radio') {
              ngModel.$setViewValue($attrs['value']);
            }
            return $timeout(function() {
              ngModel.$setTouched();
              ngModel.$setDirty();
            });
          });
        });

        $scope.$watch($attrs["ngDisabled"], function(newValue) {
          if (newValue != null) {
            jQuery(element).iCheck(newValue ? "enable" : "disable");
          } else {
            jQuery(element).iCheck("enable");
          }
          return jQuery(element).iCheck("updated");
        });

      }, 50);
    }

    return {
      scope: false,
      require: ["^formGroup", "icheck", "ngModel"],
      controller: "FormElementController",
      link: {
        pre: icheckPreLink,
        post: icheckPostLink
      }
    };
  };

  icheck.$inject = ["$timeout", "$parse", "FormControlElement"];

  angular.module('casden.form').directive("icheck", icheck);

})(angular);
