(function(angular) {

  "use strict";

  var hasProp = {}.hasOwnProperty;

  function extend (child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key))
        child[key] = parent[key];
    }

    function ctor() {
      this.constructor = child;
    }

    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
    child.__super__ = parent.prototype;
    return child;
  }

  angular.module('casden.utils').value('$extend', extend);

})(angular);
