(function(angular) {

  "use strict";

  function openModal ($uibModal) {

    function openModalLink ($scope, element, $attrs) {

      function openModal () {
        $uibModal.open({
          animation: true,
          templateUrl: $attrs.openModal
        });
      }

      $attrs.$observe('openModalOn', function(newValue, oldValue) {
        if(oldValue) {
          element.off(oldValue, openModal);
        }

        element.on(newValue, openModal)
      });
      
     
    }

    return {
      restrict: 'A',
      scope: {
        openModalOn: '@',
        openModal: '@'
      },
      link: openModalLink
    }
  }

  openModal.$inject = ["$uibModal"];

  angular.module('app').directive('openModal', openModal);

})(angular);
