(function(angular) {

  "use strict";

  /**
  * Entrer une date en trois champs distincts (jour / mois / année)
  * Il est possible de greffer un champ ngModel sur la directive pour récupérer
  * la valeur du champ, sinon en cas de post la valeur sera contenu dans les
  * champs suivants :
  *
  * {{name}}_full - Date entière au format ISO
  * {{name}}_jour - Jour de 1 à 31
  * {{name}}_mois - Mois de 1 à 12
  * {{name}}_annee - Année entrée
  */
  function dateMultifield () {

    function dateMultifieldLink ($scope, element, $attributes, ctrls) {
      var form = ctrls[0];
      var ngModel = ctrls[1];


      // Si jour ou mois ou annee change.
      $scope.$watchGroup(['jour', 'mois', 'annee'], function() {

        // Répercution de l'attribut $dirty.
        if ($scope.vm.isDirty()) {
          $scope.vm.valueField().$setDirty();
          if(ngModel) ngModel.$setDirty();
        }

        // Répercution de l'attribut $touched
        if ($scope.vm.isTouched()) {
          $scope.vm.valueField().$setTouched();
          if(ngModel) ngModel.$setTouched();
        }

        $scope.vm.checkValue();
        if(ngModel)  {
          ngModel.$setViewValue($scope.value);
        }

      });

      // Si une directive ngModel est spécifiée.
      if(ngModel) {
        ngModel.$render = function() {
          $scope.vm.render(ngModel.$viewValue);
        };
      }
    }

    return {
      restrict: "E",
      require: ["^form", "?ngModel"],
      scope: {
        name: "@"
      },
      controller: "DateMultifieldController",
      controllerAs: "vm",
      template: [
        "<div class=\"row\" ng-form=\"dateForm\">",
          "<div class=\"col-xs-3\">",
            "<input type=\"number\" ",
                   "class=\"form-control input-sm\" ",
                   "name=\"{{name}}_jour\" ",
                   "ng-model=\"jour\" ",
                   "ng-maxlength=\"2\" ",
                   "placeholder=\"JJ\" ",
                   "min=\"0\" ",
                   "required ",
            "></input>",
          "</div><!-- /.col -->",
          "<div class=\"col-xs-3\">",
            "<input type=\"number\" ",
                   "class=\"form-control input-sm\" ",
                   "name=\"{{name}}_mois\" ",
                   "ng-model=\"mois\" ",
                   "ng-maxlength=\"2\" ",
                   "placeholder=\"MM\" ",
                   "min=\"0\"",
                   "required ",
            "></input>",
          "</div><!-- /.col -->",
          "<div class=\"col-xs-6\">",
            "<input type=\"number\" ",
                   "class=\"form-control input-sm\" ",
                   "name=\"{{name}}_annee\" ",
                   "ng-model=\"annee\" ",
                   "ng-maxlength=\"4\" ",
                   "placeholder=\"AAAA\" ",
                   "min=\"0\"",
                   "required ",
            "></input>",
          "</div><!-- /.col -->",
          "<input type=\"hidden\" ",
                 "name=\"{{name}}\" ",
                 "ng-model=\"value\" ",
          "></input>",
        "</div><!-- /.row -->"
      ].join(""),
      link: dateMultifieldLink
    };
  };

  angular.module('casden.form').directive('dateMultifield', dateMultifield);

})(angular);
