(function(angular) {

  "use strict";

  function connectionIndicator () {
    return {
      restrict: "E",
      scope: {},
      templateUrl: "templates/connection-indicator.html",
      controller: 'ConnectionIndicatorController',
      controllerAs: 'vm'
    };
  }

  angular.module("app").directive(
    'connectionIndicator',
    connectionIndicator
  );

})(angular);
