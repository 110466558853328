(function(angular) {

  var subscriptionsServiceFactory = function($cookieBDD, $analytics) {

    var table = 'subscriptions';

    /**
    * Récupérer toutes les inscriptions sauvegardées
    *
    * @return array
    */
    var all = function() {
      return $cookieBDD.all(table);
    };

    /**
    * Sauvegarde une inscription.
    *
    * @param object subscription
    */
    var post = function(subscription) {
      $analytics.eventTrack("Sauvegarde d'un bulletin de contact");
      return $cookieBDD.insert(table, subscription);
    };

    /**
    * Supprime une inscription.
    *
    * @param string key
    */
    var _delete = function(subscription) {
      return $cookieBDD.delete(table, subscription.$$key);
    };

    var service = {
      all: all,
      post: post,
      delete: _delete
    };

    return service;
  };

  subscriptionsServiceFactory.$inject = ['$cookieBDD', '$analytics'];

  angular.module('app').factory('subscriptions', subscriptionsServiceFactory);

})(angular);
