(function(angular) {

  var ContentController = function($scope, lodash , $uibModal, subscriptions) {
    var vm = this;
    var board = null;
    var name = null;

    vm.getName = function() {
      return name;
    };

    vm.setName = function(newName) {
      name = newName;
    };

    vm.hasName = function() {
      return vm.getName() != null
             && vm.getName() != undefined
             && vm.getName().trim() != '';
    };

    vm.setBoard = function(newBoard) {
      board = newBoard;
    };

    vm.getBoard = function() {
      return board;
    };

    vm.hasBoard = function() {
      return vm.getBoard() != null
             && vm.getBoard() != undefined;
    };

    vm.getTemplate = function() {
      return './content/body/' + ((vm.hasName()) ? name : 'empty') + '.html';
    };

    vm.isEnabled = function() {
      return vm.hasBoard()
             && vm.hasName()
             && vm.getBoard().getEnabledContent() == vm.getName();
    };

    vm.enable = function() {
      if(vm.hasBoard()) {
        vm.getBoard().setEnabledContent(vm.getName());
      }
    };

    vm.disable = function() {
      if(vm.hasBoard()) {
        if(vm.getBoard().getEnabledContent() == vm.getName()) {
          vm.getBoard().disableContent();
        }
      }
    };

    vm.toggle = function() {
      if(vm.isEnabled()) {
        vm.disable();
      }
      else {
        vm.enable();
      }
    };

    return vm;
  };

  ContentController.$inject = ['$scope', 'lodash', '$uibModal'];

  angular.module('app').controller('ContentController', ContentController);

})(angular);
