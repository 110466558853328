(function(angular) {

  "use strict";

  function FormErrorHandlerFactory () {
    var NO_MESSAGE = jQuery("no message");

    function FormErrorHandler(formElement, localElement, selecter) {

      if (selecter == null) {
        selecter = "";
      }

      this.form = formElement;
      this.local = localElement;
      this.formElement = null;
      this.errorName = null;
      this.select(selecter);

    }

    FormErrorHandler.prototype.select = function(selecter) {

      if ((selecter != null) && selecter.trim() === "") {
        this.formElement = this.local != null ? this.local.getFormElement() : null;
        this.errorName = null;
      }

      if ((selecter != null) && selecter.indexOf("@") > -1) {
        var datas = selecter.split("@");
        this.formElement = this.form.getFormElement().findByName(datas[1].trim());
        return this.errorName = datas[0];
      } else {
        this.formElement = this.local != null ? this.local.getFormElement() : null;
        return this.errorName = selecter;
      }
    };

    FormErrorHandler.prototype.hasError = function() {
      if (this.formElement != null) {
        return this.formElement.hasError(this.errorName) && this.formElement.canFeedback();
      } else {
        return false;
      }
    };

    FormErrorHandler.prototype.errorMessage = function() {
      if (this.formElement != null) {
        var error = this.formElement.errorMessage(this.errorName);
        if (error != null) {
          return error.clone();
        } else {
          return NO_MESSAGE.clone();
        }
      } else {
        return NO_MESSAGE.clone();
      }
    };

    return FormErrorHandler;
  };

  angular.module('casden.form').factory(
    "FormErrorHandler",
    FormErrorHandlerFactory
  );

})(angular);
