angular.module('app', [
  'ngLodash',
  'ui.bootstrap',
  'ui.select',
  'ngAnimate',
  'ngSanitize',
  'angulartics',
  'angulartics.google.analytics',
  'casden.form',
  'ngCookies',
  'casden.flipbook'
]);
