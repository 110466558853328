(function(angular) {

  "use strict";

  function showOnError (FormErrorHandler, $timeout) {

    function showOnErrorLink (scope, iElem, iAttrs, ctrls) {

      var appForm = ctrls[0];
      var formGroup = ctrls[1];
      var formErrorHandler = new FormErrorHandler(appForm, formGroup || appForm);

      $timeout(function() {
        iAttrs.$observe('showOnError', function(value) {
          formErrorHandler.select(value);
        });
      });

      scope.$watch(
        function() {
          return formErrorHandler.hasError();
        },
        function(value) {
          if (value) {
            return iElem.show();
          } else {
            return iElem.hide();
          }
        }
      );

    }

    return {
      restrict: "A",
      scope: false,
      require: ["^appForm", "^?formGroup"],
      link: showOnErrorLink
    };
  };

  showOnError.$inject = ["FormErrorHandler", "$timeout"];

  /*
   * ngShow pour les erreurs de formulaires.
   */
  angular.module('casden.form').directive("showOnError", showOnError);

})(angular);
