(function(angular) {

  "use strict";

  /*
   * Fonction de clonage selon : https://coffeescript-cookbook.github.io/chapters/classes_and_objects/cloning
   * Option supplémentaire : clonage de tableau.
   */
  function clone (obj) {

    if (Array.isArray(obj)) {
      copy = [];
      for (var i = 0; i < obj.length; ++i) {
        copy.push(clone(obj[i]));
      }
      return copy;
    }

    if ((obj == null) || typeof obj !== 'object') {
      return obj;
    }

    if (obj instanceof Date) {
      return new Date(obj.getTime());
    }

    if (obj instanceof RegExp) {
      var flags = '';
      if (obj.global != null) {
        flags += 'g';
      }
      if (obj.ignoreCase != null) {
        flags += 'i';
      }
      if (obj.multiline != null) {
        flags += 'm';
      }
      if (obj.sticky != null) {
        flags += 'y';
      }
      return new RegExp(obj.source, flags);
    }

    var newInstance = new obj.constructor();
    for (var key in obj) {
      newInstance[key] = clone(obj[key]);
    }

    return newInstance;
  };

  angular.module('casden.utils').value("$clone", clone);

})(angular);
