(function(angular) {

  "use strict";

  function FormControlElementFactory (IFormElement, $extend) {

    function FormControlElement() {
      FormControlElement.__super__.constructor.call(this);
    }

    $extend(FormControlElement, IFormElement);

    FormControlElement.prototype.angularFormController = function() {
      var angularFormController = FormControlElement.__super__.angularFormController.call(this);

      if (angularFormController != null) {
        return angularFormController[this.name];
      } else {
        return null;
      }
    };

    FormControlElement.prototype.isTouched = function() {
      var angularFormController = this.angularFormController();
      return (angularFormController != null) && angularFormController.$touched;
    };

    FormControlElement.prototype.isDirty = function() {
      var angularFormController = this.angularFormController();
      return (angularFormController != null) && angularFormController.$dirty;
    };

    FormControlElement.prototype.hasError = function(types) {
      if (types == null) {
        types = void 0;
      }

      if (this.disabled) {
        return false;
      }

      var angularFormController = this.angularFormController();
      var result = false;

      if ((types != null) && types.trim() !== "" && (angularFormController != null)) {
        types = types.split(",");
        for (var i = 0; i < types.length; ++i) {
          result = result || angularFormController.$error[types[i].trim()];
        }
      } else if (angularFormController != null) {
        result = angularFormController.$invalid;
      }

      return result;
    };

    FormControlElement.prototype.hasSuccess = function() {

      if (this.disabled) {
        return true;
      }

      var angularFormController = this.angularFormController();

      return !(angularFormController != null) || angularFormController.$valid;
    };


    FormControlElement.prototype.hasWarning = function(value) {

      if (value == null) {
        value = void 0;
      }

      if (value != null) {
        return (this.__hasWarning = value) && !this.disabled;
      } else {
        return this.__hasWarning && !this.disabled;
      }

    };

    return FormControlElement;
  };

  FormControlElementFactory.$inject = ['IFormElement', '$extend'];

  angular.module('casden.form').factory('FormControlElement', FormControlElementFactory);

})(angular);
