(function(angular) {

  function ApplicationCacheServiceFactory($window, $rootScope, $q, $cookies) {

    var __installationProgress = 0;
    var __alreadyInstalled = $cookies.getObject('app__alreadyInstalled');
    var __upToDate = false;
    var __installed = false;
    var __updated = false;
    var __errors = false;

    /**
    * Config pour cookie, expiration dans +3ans.
    */
    var __expires = function() {
      var date = new Date();
      date.setTime(date.getTime() + (3 * 365 * 24 * 3600 *1000));
      return {
        expires: date
      };
    };

    $cookies.putObject(
      'app__alreadyInstalled',
      __alreadyInstalled,
      __expires()
    );

    var applicationCache = $window.applicationCache;

    function isInstallation() {
      return !__alreadyInstalled;
    }

    function hasError() {
      return __errors;
    }

    function isUpToDate() {
      return __upToDate;
    }

    function isInstalled() {
      return __installed;
    }

    function isUpdated() {
      return __updated;
    }

    function isUpdate() {
      return __alreadyInstalled;
    }

    function update () {
      __installationProgress = 0;
      __alreadyInstalled = $cookies.getObject('app__alreadyInstalled');
      __upToDate = false;
      __installed = false;
      __updated = false;
      __errors = false;
      applicationCache.update();
    }

    function swapCache () {
      applicationCache.swapCache();
    }

    function getInstallationProgress () {
      return __installationProgress;
    }

    function isSearchingForUpdates () {
      return applicationCache.status === applicationCache.CHECKING;
    }

    function isDownloading () {
      return applicationCache.status === applicationCache.DOWNLOADING;
    }

  	applicationCache.addEventListener('error', function (e) {
      $rootScope.$apply(function() {
        __errors = true;
      });
  	}, false);

    applicationCache.addEventListener('obsolete', function (e) {
      $rootScope.$apply(function() {
        __errors = true
      });
  	}, false);

  	applicationCache.addEventListener('checking', function() {
      $rootScope.$apply(function() {

      });
    }, false);

  	applicationCache.addEventListener('noupdate', function(){
      $rootScope.$apply(function() {
        __upToDate = true;
      });
  	}, false);

  	applicationCache.addEventListener('downloading', function(){
      $rootScope.$apply(function() {

      });
  	}, false);

  	applicationCache.addEventListener('progress', function(e) {
      $rootScope.$apply(function() {
        __installationProgress = Math.round(100*e.loaded/e.total);
      });
  	}, false);

  	applicationCache.addEventListener('updateready', function() {
      $rootScope.$apply(function() {
        __updated = true;
      });
  	}, false);

  	applicationCache.addEventListener('cached', function() {
      $rootScope.$apply(function() {
        __installProgress = 100;
        __installed = true;
      });

      $cookies.putObject('app__alreadyInstalled', true, __expires());
  	}, false);

    return {
      update: update,
      swapCache: swapCache,
      getInstallationProgress: getInstallationProgress,
      isSearchingForUpdates: isSearchingForUpdates,
      isDownloading: isDownloading,
      isInstallation: isInstallation,
      isInstalled: isInstalled,
      isUpdate: isUpdate,
      isUpdated: isUpdated,
      isUpToDate: isUpToDate,
      hasError: hasError,
      native: applicationCache
    };

  }

  ApplicationCacheServiceFactory.$inject = [
    "$window",
    "$rootScope",
    "$q",
    "$cookies"
  ];

  angular.module("app").service("$appCache", ApplicationCacheServiceFactory);

})(angular);
