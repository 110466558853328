(function(angular) {

  "use strict";

  /*
   * Fonction servant à convertir depuis camelCase vers la norme utilisée pour
   * les attributs dans le HTML. Fonction tirée des sources d'angular.
   */
  var SNAKE_CASE_REGEXP = /[A-Z]/g;

  function snakeCase (name, separator) {
    if (separator == null) {
      separator = '_';
    }

    return name.replace(SNAKE_CASE_REGEXP, function(letter, pos) {
      return (pos ? separator : '') + letter.toLowerCase();
    });
  };

  angular.module('casden.utils').value("$snakeCase", snakeCase);

})(angular);
