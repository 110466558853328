(function(angular) {

  "use strict";

  var boardDirective = function() {
    return {
      restrict: "E",
      scope: {},
      transclude: true,
      template: '<div ng-transclude></div>',
      replace: true,
      controller: 'BoardController',
      controllerAs: 'vm'
    };
  };

  angular.module('app').directive('board', boardDirective);

})(angular);
