(function(angular) {

  "use strict";

  function FormGroupElementFactory (IComposedFormElement, $extend) {

    /*
     * Cet élément composite marque les form-group de bootstrap, ou du moins
     * un label et plusieurs contrôles de formulaires.
     */
    $extend(FormGroupElement, IComposedFormElement);

    function FormGroupElement() {
      FormGroupElement.__super__.constructor.call(this);
    }

    return FormGroupElement;
  };

  FormGroupElementFactory.$inject = ['IComposedFormElement', '$extend'];

  angular.module('casden.form').factory(
    'FormGroupElement',
    FormGroupElementFactory
  );

})(angular);
