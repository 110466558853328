(function(angular) {

  "use strict";

  var headerDirective = function() {
    return {
      restrict: "E",
      templateUrl: "templates/header.html",
      scope: {}
    };
  };

  headerDirective.$inject = [];

  angular.module('app').directive('header', headerDirective);

})(angular);
