(function(angular) {

  /**
  * Initialise AT Internet.
  */
  function initAtInternet (ENV, $window) {
    <!--
    xtnv = $window.document;       //parent.document or top.document or document
    xtsd = ENV.xtsd;
    xtsite = ENV.xtsite;
    xtn2 = ENV.xtn2;        // level 2 site ID
    xtpage = location.pathname + location.search  + location.hash.replace('#', "::"); //page name (with the use of :: to create chapters)
    xtdi = "";        //implication degree
    xt_multc = "";		//all the xi indicators (like "&x1=...&x2=....&x3=...")
    xt_at = "";        //user ID (string)
    xt_ac = "";        //category ID

    console.log('Xiti initialisé avec : ', {
      xtsd: ENV.xtsd,
      xtsite: ENV.xtsite,
      xtn2: ENV.xtn2
    });

    //do not modify below
    if ($window.xtparam!=null){$window.xtparam+="&ac="+xt_ac+"&at="+xt_at+xt_multc;}
    else{$window.xtparam="&ac="+xt_ac+"&at="+xt_at+xt_multc;};
    //-->

    console.log('Inclusion xtcore (js/xtcore.js)...');
    var xtcore_script = $window.document.createElement("script");

    xtcore_script.type = "text/javascript";
    xtcore_script.src = "js/xtcore.js";

    $window.document.body.appendChild(xtcore_script);
  }

  initAtInternet.$inject = ['ENV', '$window'];

  angular.module('app').run(initAtInternet);

})(angular);
