(function(angular) {

  function FooterController () {
    var vm = this;

    vm.showMentionsLegales = false;

    return vm;
  }

  angular.module('app').controller(
    'FooterController',
    FooterController
  )

})(angular);
