(function(angular) {

  "use strict";

  function PageController ($scope, $templateRequest, $compile, $rootScope) {
    var vm = this;

    vm._active = false
    vm._parent = null
    vm._rootElement = null
    vm._content = null
    vm._contentScope = null

    $scope.$on('$destroy', function() {
      if(vm._contentScope) {
        vm._contentScope.$destroy()
        vm._contentScope = null
      }
    })

    vm.flipTo = function(page) {
      if(vm._parent) {
        vm._parent.flipTo(page)
      }
    }

    vm.setParent = function(flipbook) {
      if(flipbook != vm._parent) {
        if(vm._parent != null) {
          var oldParent = vm._parent;
          vm._parent = null;
          oldParent.unregisterPage(vm);
        }

        vm._parent = flipbook;

        if(vm._parent) {
          vm._parent.registerPage(vm);
        }
      }
    }

    vm.setActive = function(result) {
      if(result) {
        vm.enable();
      }
      else {
        vm.disable();
      }
    }

    vm.enable = function() {
      if(!vm._active) {
        if(vm._rootElement) {
          vm._rootElement.append(vm.getContent())
          vm._rootElement.addClass(
            (vm._parent.getPageNumber(this) % 2 === 0) ? 'even' : 'odd'
          )
        }
        vm._active = true;
      }
    }

    vm.disable = function() {
      if(vm._active) {
        if(vm._rootElement) {
          vm._rootElement.empty()
          vm._rootElement.removeClass('odd')
          vm._rootElement.removeClass('even')
        }
        vm._active = false;
      }
    }

    vm.isActive = function() {
      return vm._active;
    }

    vm.setRootElement = function(element) {
      if(vm._rootElement != element) {
        if(vm._content && vm._rootElement) {
          vm._rootElement.empty()
          vm._rootElement = null
        }

        vm._rootElement = element

        if(vm._active) {
          vm._rootElement.append(vm.getContent())
        }
      }
    }

    vm.setTemplate = function(template) {
      return $templateRequest(template).then(function(response) {
        if(vm._content != null) {
          vm._contentScope.$destroy()
          vm._contentScope = null
          if(vm._rootElement != null) {
            vm._rootElement.empty()
          }
          vm._content.remove()
          vm._content = null
        }

        vm._contentScope = $scope.$new(false, $scope.$parent)
        vm._content = response;

        if(vm._rootElement && vm._active) {
          vm._rootElement.append(vm.getContent())
        }
      });
    }

    vm.getContent = function() {
      if(vm._content && vm._contentScope) {
        return $compile(vm._content)(vm._contentScope)
      }
      else {
        return null
      }
    }

    vm.isOnline = function() {
      return $rootScope.onLine;
    }

    return vm;
  }

  PageController.$inject = [
    '$scope', '$templateRequest', '$compile', '$rootScope'
  ];

  angular.module('casden.flipbook').controller(
    "PageController", PageController
  );

})(angular);
