(function(angular) {

  "use strict";

  var cardDirective = function() {

    var link = function($scope, element, $attributes, boardController) {
      $scope.vm.setBoard(boardController);
      $attributes.$observe('name', function(newName) {
         $scope.vm.setName(newName);
      });
    };

    return {
      restrict: 'E',
      scope: {
        'name':'@',
        'analyticsLabel':'@'
      },
      replace: true,
      templateUrl: './templates/card.html',
      require: '^board',
      controller: 'CardController',
      controllerAs: 'vm',
      link: link
    };
  };

  angular.module('app').directive('card', cardDirective);

})(angular);
