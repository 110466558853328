(function(angular) {

  "use strict";

  function OnlineServiceFactory ($rootScope) {

    // Working demo: http://jsbin.com/ozusa6/2/
    function triggerEvent(type) {
  	  var event = document.createEvent('HTMLEvents');
  	  event.initEvent(type, true, true);
  	  event.eventName = type;
  	  (document.body || window).dispatchEvent(event);
  	}

  	function isOnline() {
  	  // make sync-ajax request
  	  var xhr = new XMLHttpRequest();
      var result = false;

  	  // phone home
  	  xhr.open('HEAD', '/test_offline.html', false); // async=false
  	  try {
  	    xhr.send();
  	    result = true;
  	  } catch (e) {
  	    // throws NETWORK_ERR when disconnected
  	    result = false;
  	  }

  	  return result;
  	}

    function isOffline() {
      return !isOnline();
    }

    return {
      isOnline: isOnline,
      isOffline: isOffline
    }
  }

  OnlineServiceFactory.$inject = ["$rootScope"];

  angular.module('app').factory('$online', OnlineServiceFactory);

})(angular);
