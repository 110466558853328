(function(angular) {

  "use strict";

  /**
  *  Fonctions utiles pour dialoguer avec le serveur.
  */
  function ServerFactory ($http, $q) {

    /**
    * Envoyer un bulletin sur le serveur.
    *
    * @param object bulletin Bulletin à envoyer.
    *
    * @return object Promise qui se résoud une fois l'envoi terminé.
    */
    function saveBulletin(bulletin) {
      var deferred = $q.defer();
      var request = $http.post("save.php", bulletin);

      request.then(function (data) {
        if(data && data.data.success) {
          console.log('Envoi de l\'inscription terminée...');
          deferred.resolve(data.data.id);
        }
        else {
          console.log('Echec de l\'envoi...');
          deferred.reject();
        }
      }, function (data) {
        console.log('Echec de l\'envoi...');
        deferred.reject();
      });

      return deferred.promise;
    }

    var server = {
      saveBulletin: saveBulletin
    }

    return server;
  };

  ServerFactory.$inject = ["$http", "$q"];

  angular.module("app").factory("server", ServerFactory);

})(angular);
