(function(angular) {

  "use strict";

  function ConnectionIndicatorController ($scope, $rootScope) {
    var vm = this;
    var __isVisible = true;

    vm.getMode = function() {
      return ($rootScope.onLine ? 'online' : 'offline');
    }

    vm.hide = function() {
      __isVisible = false;
    }

    vm.show = function() {
      __isVisible = true;
    }

    vm.isVisible = function() {
      return __isVisible;
    }

    $scope.$on('offline', function() {
      vm.show();
    });

    $scope.$on('online', function() {
      vm.show();
    });

    return vm;
  }

  ConnectionIndicatorController.$inject = ["$scope", "$rootScope"];

  angular.module('app').controller(
    'ConnectionIndicatorController', ConnectionIndicatorController
  );

})(angular);
