(function(angular) {

  "use strict";

  function simpleVideo () {

    return {
      restrict: 'E',
      templateUrl: 'templates/simpleVideo.html',
      scope: {
        type: '@',
        src: '@',
        poster: '@'
      },
      controller: 'SimpleVideoController',
      controllerAs: 'vm'
    }

  }

  angular.module('app').directive('simpleVideo', simpleVideo);

})(angular);
