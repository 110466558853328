(function(angular) {

  "use strict";

  /*
   * Regex valeur entière, utilisée par les méthodes de tests d'entiers.
   */
  var INT_TYPE_REGEX = /^(\+|\-)?\d*$/i;

  /*
   * Directive appliquant un test et un binding de type nombre entier sur un champ de formulaire.
   *
   * @see  https://docs.angularjs.org/guide/directive  Page de la documentation angular sur les directives.
   *
   * Cette directive angular permet d'appliquer un test de type nombre entier sur un champ du formulaire,
   * cet à dire de tester que la valeur du champ est bien un entier (positif ou négatif), et de la transformer
   * en valeur de type Number entier dans le modèle associé.
   *
   * Bien entendu ce binding est à double sens, cet à dire que si la variable du modèle
   * correspondant au champ est modifiée, la valeur du champ sera mise à jour en conséquence.
   *
   * Une valeur de champ vide est considérée comme valide, afin d'éviter ce problème, il faut
   * utiliser l'attribut HTML5 {@link http://www.w3schools.com/tags/att_input_required.asp required}.
   *
   * Pour utiliser cette directive il suffit d'ajouter l'attribut int-type à votre champ de formulaire.
   *
   * Exemple :
   *
   * <input type="number" int-type name="nombreDeJours" />
   */
  function intType () {
    function intTypeLink ($scope, element, $attributes, ngModel) {
      /*
       * Valide que la valeur passée à la vue est bien un entier.
       *
       * Ce test ne s'effectue que si la valeur passée à la vue
       * est non vide, dans le cas contraire elle sera considéré systématiquement
       * comme valide.
       *
       * @param string modelValue Valeur entrée dans le modèle.
       * @param string viewValue Valeur entrée dans la vue.
       *
       * @return boolean True si le résultat du test est valide.
       */
      ngModel.$validators.intFormat = function(modelValue, viewValue) {
        if (viewValue == null) {
          viewValue = modelValue;
        }
        if (ngModel.$isEmpty(viewValue)) {
          return true;
        } else {
          return INT_TYPE_REGEX.test("" + viewValue);
        }
      };

      /*
       * Transforme une valeur entrée dans le formulaire
       * en objet Number dans le modèle.
       *
       * Si la valeur n'est pas un entier valide elle sera
       * retournée tel quel.
       *
       * @param  string value Valeur entrée dans la vue.
       *
       * @return int | string Valeur du modèle.
       */
      ngModel.$parsers.unshift(function(value) {
        if (INT_TYPE_REGEX.test(value)) {
          return parseInt(value);
        } else {
          return void 0;
        }
      });

      /*
       * Transforme une valeur du modèle en valeur affichée
       * dans la vue.
       *
       * Si la valeur est indéfinie, la chaîne vide sera
       * retournée.
       *
       * Si la valeur est un nombre entier elle est renvoyée
       * telle quelle.
       *
       * Sinon elle est renvoyée telle qu'elle.
       *
       * @param string value Valeur entrée dans le modèle.
       *
       * @return string Valeur affichée.
       */
      ngModel.$formatters.unshift(function(value) {
        if (typeof value === "undefined" || !(value != null)) {
          return "";
        } else if (typeof value === "number" && INT_TYPE_REGEX.test("" + value)) {
          return value;
        } else if (INT_TYPE_REGEX.test(value)) {
          return parseInt(value);
        } else {
          return value;
        }
      });
    }

    return {
      restrict: "A",
      require: "ngModel",
      link: intTypeLink
    };
  }

  angular.module('casden.form').directive("intType", intType);

})(angular);
