(function(angular) {

  "use strict";

  function formRequired () {
    return {
      restrict: "E",
      replace: true,
      scope: false,
      template: '<span class="required">*</span>'
    };
  }

  /*
   * Notifier qu'un label est requis
   */
  angular.module('casden.form').directive("formRequired", formRequired);

})(angular);
