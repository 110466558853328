(function(angular) {

  function SimpleVideoController($scope, $rootScope) {
    var vm = this;

    vm.isOnline = function() {
      return $rootScope.onLine;
    }

    return vm;
  }

  SimpleVideoController.$inject = ['$scope', '$rootScope'];

  angular.module('app').controller(
    'SimpleVideoController', SimpleVideoController
  );

})(angular);
