(function(angular) {

  "use strict";

  var AttrsBinderFactory;

  AttrsBinderFactory = function() {

    function AttrsBinder(object) {
      this.__unbindFunctions = [];
      this.__toBind = {};
      this.__object = object;
    }

    AttrsBinder.prototype.use = function(name, toAsign) {
      if (toAsign == null) {
        toAsign = void 0;
      }

      if (!(this.__toBind[name] != null)) {
        this.__toBind[name] = [];
      }

      if (!(toAsign != null)) {
        toAsign = name;
      }

      if (typeof toAsign === 'function') {
        return this.__toBind[name].push(toAsign);
      } else {
        var self = this;
        return this.__toBind[name].push(function(value) {
          return self.__object[toAsign] = value;
        });
      }
    };

    AttrsBinder.prototype.unbind = function() {
      var unbindFunction;

      for (var i = 0; i < this.__unbindFunctions.length; i++) {
        unbindFunction = this.__unbindFunctions[i];
        unbindFunction();
      }

      return this.__unbindFunctions = [];
    };

    AttrsBinder.prototype.bind = function(iAttrs) {
      var name, results, toAsigns;

      this.unbind();
      results = [];

      for (name in this.__toBind) {
        toAsigns = this.__toBind[name];
        for (var i = 0; i < toAsigns.length; ++i) {
          results.push(
            this.__unbindFunctions.push(iAttrs.$observe(name, toAsigns[i]))
          );
        }
      }

      return results;
    };

    return AttrsBinder;
  };

  angular.module('casden.utils').factory("AttrsBinder", AttrsBinderFactory);

})(angular);
