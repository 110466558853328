(function(angular) {

  "use strict";

  var NO_MESSAGE = jQuery("no message");

  function IFormElementFactory (AttrsBinder) {

    function IFormElement() {
      this.parent = null;
      this.DOMelement = null;
      this.name = "UnnamedFormElement";
      this.disabled = false;
      this.__hasWarning = false;
      this.__errors = {};
      this.__attrsBinder = new AttrsBinder(this);
      this.__attrsBinder.use('name');
      this.__attrsBinder.use('disabled');
    }

    IFormElement.prototype.isParent = function(element) {
      if (element != null) {
        if (element === this.parent) {
          return true;
        } else if (this.parent != null) {
          return this.parent.isParent(element);
        } else {
          return false;
        }
      } else {
        return false;
      }
    };


    /*
     * @return ngForm le controlleur ngForm auquel l'élément est rattaché.
     */
    IFormElement.prototype.angularFormController = function() {
      if (this.parent != null) {
        return this.parent.angularFormController();
      } else {
        return null;
      }
    };


    /*
     * Permet de retrouver un élément dans la hiérarchie du formulaire.
     *
     * Cette méthode ne retourne qu'un élément ou null, si la recherche
     * n'aboutit pas.
     *
     * Si l'objet appellé est unique (non composé) cette méthode le retournera
     * si le nom passé en paramètre est le sien.
     *
     * @param string elementName Nom de l'élément cherché.
     * @return IFormElement l'élément ayant le nom elementName
     */
    IFormElement.prototype.findByName = function(elementName) {
      if (elementName === this.name) {
        return this;
      } else {
        return null;
      }
    };


    /*
     * @return boolean true, si l'élément a subit un focus.
     */
    IFormElement.prototype.isTouched = function() {
      return false;
    };


    /*
     * @return boolean true, si la valeur de l'élément a été renseignée.
     */
    IFormElement.prototype.isDirty = function() {
      return false;
    };


    /*
     * Retourne vrai si l'élément souhaite notifier son état. L'élément souhaite
     * par défaut le faire, sous couvert que son parent l'autorise (si parent
     * il y a)
    #
     * @param IFormElement child Enfant demandant la permission
    #
     * @return boolean true, si l'élément souhaite notifier son état
     */
    IFormElement.prototype.canFeedback = function(child) {
      if (child == null) {
        child = this;
      }
      if (this.parent != null) {
        return this.parent.canFeedback(child);
      } else {
        return true;
      }
    };


    /*
     * @param string (optionel) types des erreurs au format nom1, nom2, nom3
    #
     * @return boolean true si l'objet est en état d'erreur, ou si un seul des
     *                 états renseignés dans types est actif.
     */
    IFormElement.prototype.hasError = function(types) {
      if (types == null) {
        types = void 0;
      }
      return false;
    };


    /*
     * @return boolean true s'il n'y a pas d'erreur... et pas de succès et pas de notification en général.
     */
    IFormElement.prototype.isNeutral = function() {
      return (!this.hasError() && !this.hasSuccess()) || !this.canFeedback();
    };


    /*
     * @return boolean true si le champ est valide, par défaut oui.
     */
    IFormElement.prototype.hasSuccess = function() {
      return true;
    };


    /*
     * @return boolean true si le champ émet des alertes.
     */
    IFormElement.prototype.hasWarning = function(value) {
      if (value == null) {
        value = void 0;
      }
      if (value != null) {
        return (this.__hasWarning = value) && !this.disabled;
      } else {
        return this.__hasWarning && !this.disabled;
      }
    };


    /*
     * Si le paramètre message est renseigné, le message d'erreur sera configuré
     * pour l'élément courant (et masquera celui des hypothétiques parents)
    #
     * @param string type Nom de l'erreur.
     * @param jQuery (optionel) dom représentant le message.
    #
     * @return jQuery le contenu du message d'erreur de type renseigné en paramètre.
     */
    IFormElement.prototype.errorMessage = function(type, message) {
      if (message == null) {
        message = null;
      }
      if (message != null) {
        return this.__errors[type] = message;
      } else if (this.__errors[type] != null) {
        return this.__errors[type];
      } else if (this.parent != null) {
        return this.parent.errorMessage(type);
      } else {
        return NO_MESSAGE;
      }
    };

    /*
     * Détache l'élément du DOM
     */
    IFormElement.prototype.unbind = function() {
      this.DOMelement = null;
      return this.__attrsBinder.unbind();
    };

    /*
     * Attache l'élément au DOM
     *
     * @param jQuery iElem élément du DOM
     * @param $attributes iAttrs attributs de l'élément (angularjs)
     */
    IFormElement.prototype.bind = function(iElem, iAttrs) {
      this.unbind();
      this.DOMelement = iElem;
      this.__attrsBinder.bind(iAttrs);
      return this.__createUnbindFunction();
    };


    /*
     * Retourne une fonction qui permet de détâcher l'élément du DOM
     */
    IFormElement.prototype.__createUnbindFunction = function() {
      var result = function() {
        return this.controller.unbind();
      };
      result.controller = this;
      return result;
    };

    return IFormElement;
  };

  IFormElementFactory.$inject = ['AttrsBinder'];

  /*
   * Tous les éléments du formulaire hérite de IFormElement, toutes ses classes
   * sont utilisées par le controller FormELementController et son parties
   * intégrante du fonctionnement des directives de formulaires de l'application.
   */
  angular.module("casden.form").factory("IFormElement", IFormElementFactory);

})(angular);
