(function(angular) {

  function EmptyController($scope, $rootScope) {
    var vm = this;


    return vm;
  }

  EmptyController.$inject = ['$scope', '$rootScope'];

  angular.module('app').controller(
    'EmptyController', EmptyController
  );

})(angular);
