(function(angular) {

  "use strict";

  function formGroup (FormGroupElement) {

    function formGroupPreLink (scope, iElem, iAttrs, ctrls) {

      var parentController = ctrls[0];
      var formGroupController = ctrls[1];
      formGroupController.setFormElement(new FormGroupElement());
      formGroupController.register(parentController);
      formGroupController.bind(iElem, iAttrs);

      scope.$on("$destroy", function() {
        return formGroupController.destroy();
      });
    }

    function formGroupPostLink (scope, iElem, iAttrs, ctrls) {
      var formGroupController = ctrls[1];

      scope.$watch(
        function() {
          return formGroupController.getFormElement().isNeutral();
        },
        function(value) {
          if (value) {
            return iElem.addClass("neutral");
          } else {
            return iElem.removeClass("neutral");
          }
        }
      );

      scope.$watch(
        function() {
          return formGroupController.getFormElement().hasError() && formGroupController.getFormElement().canFeedback();
        },
        function(value) {
          if (value) {
            return iElem.addClass("has-error");
          } else {
            return iElem.removeClass("has-error");
          }
        }
      );

      scope.$watch(
        function() {
          return formGroupController.getFormElement().hasSuccess() && formGroupController.getFormElement().canFeedback();
        },
        function(value) {
          if (value) {
            return iElem.addClass("has-success");
          } else {
            return iElem.removeClass("has-success");
          }
        }
      );

      scope.$watch(
        function() {
          return formGroupController.getFormElement().hasWarning() && formGroupController.getFormElement().canFeedback();
        },
        function(value) {
          if (value) {
            return iElem.addClass("has-warning");
          } else {
            return iElem.removeClass("has-warning");
          }
        }
      );

      iAttrs.$observe("disabled", function(value) {
        if (value) {
          return iElem.find("input, textarea, select, .form-group, .control-label, .control-container").attr("disabled", true);
        } else {
          return iElem.find("input, textarea, select, .form-group, .control-label, .control-container").attr("disabled", false);
        }
      });
    };

    return {
      restrict: "C",
      scope: false,
      require: ["^appForm", "formGroup"],
      controller: "FormElementController",
      link: {
        pre: formGroupPreLink,
        post: formGroupPostLink
      }
    };
  };

  formGroup.$inject = ["FormGroupElement"];

  /*
   * Groupe label-champ du formulaire
   */
  angular.module('casden.form').directive("formGroup", formGroup);

})(angular);
