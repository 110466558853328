(function(angular) {

  var BoardController = function($scope, $analytics) {
    var vm = this;
    var enabled = null;

    vm.getEnabledContent = function() {
      return enabled;
    };

    vm.setEnabledContent = function(name) {
      enabled = name;
    };

    vm.hasEnabledContent = function() {
      return vm.getEnabledContent() != null
             && vm.getEnabledContent() != undefined
             && vm.getEnabledContent().trim() != '';
    };

    vm.disableContent = function() {
      vm.setEnabledContent(null);
    };

    return vm;
  };

  BoardController.$inject = ['$scope', '$analytics'];

  angular.module('app').controller('BoardController', BoardController);

})(angular);
