(function(angular) {

  var CardController = function($scope, lodash, $analytics) {
    var vm = this;
    var board = null;
    var name = null;

    vm.setBoard = function(newBoard) {
      board = newBoard;
    };

    vm.getBoard = function() {
      return board;
    };

    vm.hasBoard = function() {
      return vm.getBoard() != null && vm.getBoard() != undefined;
    };

    vm.getName = function() {
      return name;
    };

    vm.setName = function(newName) {
      name = newName;
    };

    vm.hasName = function() {
      return vm.getName() != null
             && vm.getName() != undefined
             && vm.getName().trim() != '';
    };

    vm.getTemplate = function() {
      return './content/card/' + ((vm.hasName()) ? vm.getName() : 'empty')
                               + '.html';
    };

    vm.isEnabled = function() {
      return vm.hasBoard()
             && vm.hasName()
             && vm.getBoard().getEnabledContent() == vm.getName();
    };

    vm.isDisabled = function() {
      return vm.hasBoard()
             && vm.hasName()
             && vm.getBoard().hasEnabledContent()
             && vm.getBoard().getEnabledContent() != vm.getName();
    };

    vm.toggle = function() {
      if(vm.isEnabled()) {
        vm.disable();
      }
      else {
        vm.enable();
      }
    };

    vm.enable = function() {
      if(vm.hasBoard()) {
        vm.getBoard().setEnabledContent(vm.getName());
      }
    };

    vm.disable = function() {
      if(vm.hasBoard()) {
        if(vm.getBoard().getEnabledContent() == vm.getName()) {
          vm.getBoard().disableContent();
        }
      }
    };


    var __init = true;

    $scope.$watch(
      function() {
        return vm.isEnabled();
      },
      function(newValue) {

        if(!newValue && !__init) {
          $analytics.eventTrack($scope.analyticsLabel + " [Fermeture]");
        }

        if(newValue) {
          $analytics.eventTrack($scope.analyticsLabel + " [Ouverture]");
        }

        __init = false;
      }
    );

    return vm;
  };

  CardController.$inject = ["$scope", 'lodash', '$analytics'];

  angular.module('app').controller('CardController', CardController);

})(angular);
