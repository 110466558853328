(function(angular) {

  function __use_xt_click(a, b, c, d ,e) {
    if(window.xt_click) {
      window.xt_click(a,b,c,d,e);
    }
    else {
      window.setTimeout(function(a,b,c,d,e) {
        __use_xt_click(a,b,c,d,e);
      }, 100, a, b, c, d, e);
    }
  }

  var configureXiti = function ($analyticsProvider, ENVProvider) {

    $analyticsProvider.registerPageTrack(function (path) {
  	  __use_xt_click({}, 'F', ENVProvider.xtn2, path, null);
    });

    $analyticsProvider.registerEventTrack(function (action, properties) {
      if(properties) {
        __use_xt_click(
          {}, 'F', ENVProvider.xtn2,
          'event:'+properties.eventType+'::'+action, null
        );
      }
      else {
        __use_xt_click(
          {}, 'F', ENVProvider.xtn2,
          'event::'+action, null
        );
      }
    });

    $analyticsProvider.registerSetUsername(function (userId) {
      $analyticsProvider.settings.xiti.userId = userId;
    });

  };

  configureXiti.$inject = ['$analyticsProvider', 'ENVProvider'];

  angular.module('app').config(configureXiti);

})(angular);
