(function(angular) {

  "use strict";

  function cacheManager () {
    return {
      restrict: 'E',
      templateUrl: 'templates/cache-manager.html',
      scope: { },
      controller: 'CacheManagerController',
      controllerAs: 'vm'
    };
  }

  angular.module('app').directive('cacheManager', cacheManager);

})(angular);
