(function(angular) {

  "use strict";

  function FlipBookController ($scope) {
    var vm = this;

    vm._pages = []
    vm._currentPage = 1

    vm.onPageChange = function(newPage) {
      var leftPage = vm._currentPage
      if(vm._currentPage % 2 == 1) leftPage = vm._currentPage - 1

      for(var index = leftPage - 2; index <= leftPage + 3; ++index) {
        if(index >= 1 && index <= vm._pages.length) {
          var page = vm._pages[index - 1]
          page.setActive(false)
        }
      }

      vm._currentPage = newPage

      leftPage = vm._currentPage
      if(vm._currentPage % 2 == 1) leftPage = vm._currentPage - 1

      for(var index = leftPage - 2; index <= leftPage + 3; ++index) {
        if(index >= 1 && index <= vm._pages.length) {
          var page = vm._pages[index - 1]
          page.setActive(true)
        }
      }
    }

    vm.flipTo = function(page) {
      var leftPage = page
      if(page % 2 == 1) leftPage = page - 1

      for(var index = leftPage - 2; index <= leftPage + 3; ++index) {
        if(index >= 1 && index <= vm._pages.length) {
          var pageCtrl = vm._pages[index - 1]
          pageCtrl.setActive(true)
        }
      }

      $scope.$flipbook.turn('page', page);
    }

    vm.next = function() {
      $scope.$flipbook.turn('next');
    }

    vm.previous = function() {
      $scope.$flipbook.turn('previous');
    }

    vm.isActive = function(page) {
      if(Number.isInteger(page)) {
        if(page <= 0) return false

        var leftPage = vm._currentPage
        if(vm._currentPage % 2 == 1) {
          leftPage = vm._currentPage - 1
        }

        return page >= leftPage - 2 && page <= leftPage + 3
      }
      else {
        return vm.isActive(vm.getPageNumber(page))
      }
    }

    vm.registerPage = function(page) {
      if(!vm.hasPage(page)) {
        vm._pages.push(page)
        var isActive = this.isActive(vm._pages.length)
        if(page.isActive() != isActive) {
          page.setActive(isActive)
        }
        page.setParent(vm)
      }
    }

    vm.unregisterPage = function(page) {
      if(vm.hasPage(page)) {
        vm._pages = vm._pages.filter(function(elem) {
          return elem != page
        })
        page.setParent(null)
      }
    }

    vm.hasPage = function(page) {
      return vm.getPageNumber(page) > 0
    }

    vm.getPageNumber = function(page) {
      for(var index = 0; index < vm._pages.length; ++index) {
        if(vm._pages[index] == page) {
          return index + 1
        }
      }

      return -1
    }

    return vm;
  }

  FlipBookController.$inject = ['$scope'];

  angular.module('casden.flipbook').controller(
    "FlipBookController", FlipBookController
  );

})(angular);
