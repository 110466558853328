(function(angular) {
  
  "use strict";

  var footerDirective = function() {
    return {
      restrict: "E",
      templateUrl: "templates/footer.html",
      scope: {},
      controller: "FooterController",
      controllerAs: 'vm'
    };
  };

  footerDirective.$inject = [];

  angular.module('app').directive('footer', footerDirective);

})(angular);
