(function(angular) {

  "use strict"

  function staticInclude($templateRequest, $compile) {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      scope: false,
      link: function($scope, element, attrs, ctrl, transclude) {
        var contentScope = $scope.$new(false, $scope.$parent)

        $scope.$on('$destroy', function() {
          contentScope.$destroy()
        })

        attrs.$observe('staticInclude', function(templatePath) {
          contentScope.$destroy()
          contentScope = $scope.$new(false, $scope.$parent)

          $templateRequest(templatePath).then(function(response) {
            var contents = element.empty().html(response).contents()
            $compile(contents)(contentScope)
          })
        })
      }
    }
  }

  staticInclude.$inject = ['$templateRequest', '$compile']

  angular.module('casden.utils').directive('staticInclude', staticInclude)

})(angular);
